import {
  VehiclesIcon,
  FaqIcon1,
  EvStoriesIcon,
  ComponentsIcon,
} from '../../assets/images/svg';

export const SideBarMenuList = [
  {
    id: 1,
    icon: VehiclesIcon,
    path: '/dashboard/myvehicles',
    title: 'Vehicles',
  },
  {
    id: 2,
    icon: FaqIcon1,
    path: '/dashboard/myfaqs',
    title: 'FAQ’s',
  },
  {
    id: 3,
    icon: EvStoriesIcon,
    path: '/dashboard/myevstories',
    title: 'EV Stories',
  },
  {
    id: 4,
    icon: ComponentsIcon,
    path: '/dashboard/mycomponents',
    title: 'Components',
  },
];

export const vehiclesOptionsList = [
  {
    id: 1,
    label: 'Car',
  },
  {
    id: 2,
    label: 'Bike',
  },
  {
    id: 3,
    label: 'Cycle',
  },
  {
    id: 4,
    label: 'Scooter',
  },
];

export const faqOptionsList = [
  {
    id: 1,
    label: 'General',
  },
  {
    id: 2,
    label: 'Performance',
  },
  {
    id: 3,
    label: 'Charging',
  },
  {
    id: 4,
    label: 'Safty',
  },
  {
    id: 5,
    label: 'Environment',
  },
];
export const componentsOptionsList = [
  {
    id: 1,
    label: 'Lithium ion Batt',
  },
  {
    id: 2,
    label: 'Hydrogen cell',
  },
  {
    id: 3,
    label: 'Accessories',
  },
];
