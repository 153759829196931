import { PlusIcon } from '../../assets/images/svg';
import QuestionBlock from './QuestionBlock';

const FaqSection = ({ questions }) => {
  return (
    <div className="place-content-center bg-custom-black">
      <div className="flex justify-center place-items-center lg:space-x-3 mb-16">
        <PlusIcon />
        <h1 className=" text-3xl font-bold text-white text-center font-Poppins ">
          FAQ
        </h1>
        <PlusIcon />
      </div>
      <div className="container">
        <div className="lg:px-28 md:px-14 px-5 mb-10">
          {questions.map(({ id, question, answer }) => {
            return (
              <div className="mt-3" key={id}>
                <QuestionBlock answer={answer} question={question} />
              </div>
            );
          })}
        </div>
        <div className="flex justify-center pb-28">
          <button className="px-10 py-2.5 font-medium text-center rounded-sm bg-custom-yellow text-custom-black  text-md`">
            More FAQ &gt; &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
