import { Helmet } from 'react-helmet';
import DashboardSearch from '../../../components/Admin/Header/DashboardSearch';
import DashboardHeader from '../../../components/Admin/Header/DashboardHeader';
import ListCards from '../../../components/Admin/ListCards/ListCards';
import { useNavigate } from 'react-router-dom';

const MyEvStories = () => {
  const navigate = useNavigate();
  const navigateToAddEvStory = () =>
    navigate('/dashboard/myevstories/createEvstory');

  return (
    <div>
      <Helmet>
        <title>My EV Stories</title>
        <meta content="My EV Stories" name="description" />
      </Helmet>
      <div className="pt-10 pr-10 pl-10">
        <DashboardHeader
          isRightButton={true}
          onClickHandler={navigateToAddEvStory}
          title={'My EV Stories'}
        />
        <DashboardSearch isRightButton={false} title={'Search Ev Story'} />
        <div className="pt-10">
          <ListCards />
        </div>
      </div>
    </div>
  );
};

export default MyEvStories;
