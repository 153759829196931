import car from '../../../assets/images/RectangleCar1.png';
import { EyeIcon, EditIcon, DeleteIcon } from '../../../assets/images/svg';

const ListCards = () => {
  return (
    <div className="bg-admin-list-card-background w-full rounded-lg">
      <div className="grid lg:grid-cols-5 md:grid-cols-5  py-4 place-items-center">
        <div className="lg:col-span-1 md:col-span-1">
          <img alt="img" src={car}></img>
        </div>
        <div className="font-Poppins lg:col-span-3 md:col-span-3 p-2">
          <label className="text-custom-black font-bold">
            Mahindra XUV 700
          </label>
          <p className="pt-3">
            Mahindra XUV700 is a 5 seater SUV available in a price range of Rs.
            12.49 - 22.99 Lakh*. It is available in 23 variants, 2 engine
            options that are BS6 compliant
          </p>
        </div>
        <div className="flex  place-items-center gap-4 lg:col-span-1 md:col-span-1">
          <EyeIcon />
          <EditIcon />
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};

export default ListCards;
