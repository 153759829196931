import Footer from '../../components/Footer/Footer';
import charging from '../../assets/images/background_images/CHARGING.png';
import { useMediaQuery } from 'react-responsive';
import Navbar from '../../components/Navbar/Navbar';
import NavbarMobile from '../../components/Navbar/NavbarMobile/NavbarMobile';
import {
  isLargeScreen,
  isTabletOrMobile,
} from '../../utlils/constants/constants';
import { SearchIcon } from '../../assets/Icons/Icons';
import Select from '../../components/EvChargingStation/Select/Select';
import { useState } from 'react';
import { evChargingStations } from './constants';
import {
  HomeNavbarList,
  SubHomeNavbarProperties,
} from '../../utlils/constants/navbarConstant';

const SelectDropDownCard = ({ Icon, city }) => {
  return (
    <div className="p-6 flex flex-col justify-center">
      <div>{<Icon />}</div>
      <div className="text-center text-sm font-Poppins">
        <h1>{city}</h1>
      </div>
    </div>
  );
};

const EvChargingPage = () => {
  const isLarge = useMediaQuery(isLargeScreen);
  const isTablet = useMediaQuery(isTabletOrMobile);
  const [isDropdown, setIsDropdown] = useState(false);
  return (
    <>
      <div className="bg-custom-yellow">
        {/* Header Section */}
        <div>
          {isLarge || !isTablet ? (
            <Navbar
              navbarList={HomeNavbarList}
              navbarProperties={SubHomeNavbarProperties}
            />
          ) : (
            <NavbarMobile
              navbarList={HomeNavbarList}
              navbarProperties={SubHomeNavbarProperties}
            />
          )}
        </div>
        <div className="h-72">
          <div className="flex justify-end  mt-12 -mb-44 pb-24 lg:pb-7">
            <img alt="" src={charging} />
          </div>
          <div className="flex  flex-wrap justify-center place-items-center gap-10 p-10">
            {isLarge && <div className="line-faq"></div>}
            <h1 className="text-4xl text-center text-black font-bold">
              Charging Station Near me
            </h1>
            {isLarge && <div className="line-faq"></div>}
          </div>
          {/* Search Section */}
          <div>
            <div className="flex justify-center">
              <div className=" lg:w-2/3 w-full">
                <div
                  className={`${
                    isDropdown ? 'search-input-dropdown-open' : 'search-input'
                  }  bg-search-background flex place-items-center`}>
                  <div onClick={() => setIsDropdown(!isDropdown)}>
                    <Select isDropDownOpen={isDropdown} />
                  </div>
                  <input
                    className=" w-full text-black placeholder-black leading-tight focus:outline-none focus:shadow-outline"
                    placeholder="Type your location here..."
                    type="text"
                  />
                  <div className="p-2 search_icon_border mr-1">
                    <SearchIcon fill="white" height="25" width="25" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              {isDropdown && (
                <div className="lg:w-2/3 w-full dropdown-background">
                  <div className="flex justify-center  flex-wrap relative z-30">
                    {evChargingStations.map(({ id, city, Icon }) => {
                      return <SelectDropDownCard Icon={Icon} city={city} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-custom-black h-80">
        <p className="font-Poppins text-xs text-white font-thin text-center">
          Here are some of the Charging Stations near Shahdara, Delhi
        </p>
      </div>
      <Footer />
    </>
  );
};

export default EvChargingPage;
