import { HeroScrollIcon } from '../../assets/images/svg/index';
import Navbar from '../Navbar/Navbar';
import '../../styles/LandingPage.css';
import { useMediaQuery } from 'react-responsive';
import {
  isLargeScreen,
  //isMediumScreen,
  isTabletOrMobile,
} from '../../utlils/constants/constants';
import NavbarMobile from '../Navbar/NavbarMobile/NavbarMobile';
import TypeingAnimation from '../TypeingAnimation/TypeingAnimation';
import {
  HomeNavbarList,
  HomeNavbarProperties,
} from '../../utlils/constants/navbarConstant';

const LandingPage = () => {
  const isLarge = useMediaQuery(isLargeScreen);
  //const isMedium = useMediaQuery(isMediumScreen);
  const isTablet = useMediaQuery(isTabletOrMobile);
  const steps = [
    3000,
    'STORIES',
    3000,
    'STOCKS',
    3000,
    "FAQ'S",
    3000,
    'COMPONENTS',
  ];
  return (
    <div className="bg-cover lg:bg-landing-page-background md:landing-page-md-background bg-landing-mb-background">
      <div>
        {isLarge || !isTablet ? (
          <Navbar
            navbarList={HomeNavbarList}
            navbarProperties={HomeNavbarProperties}
          />
        ) : (
          <NavbarMobile
            navbarList={HomeNavbarList}
            navbarProperties={HomeNavbarProperties}
          />
        )}
      </div>
      <div className="grid h-screen place-items-center">
        <div className="text-center font-Poppins">
          <div className="mb-20">
            <div className="flex gap-11 place-items-center place-content-center mb-5">
              <div className="line"></div>
              <h1 className="text-4xl font-light text-white">HUB OF</h1>
              <div className="line"></div>
            </div>
            <div className="flex justify-center relative space-x-3 text-4xl lg:text-6xl font-bold text-yellow-300">
              <p className="text-white">EV</p>
              <TypeingAnimation
                loop={Infinity}
                steps={[...steps]}
                wrapper="p"
              />
            </div>
          </div>
          <p className="font-light text-white sub-title ">
            Just by visiting this site you are one step closer to know more
            about EV's
          </p>
          <div className="flex justify-center cursor-pointer mt-36 md:mt- animate-bounce">
            <HeroScrollIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
