import FaqCatagoriesCard from './FaqCatagoriesCard/FaqCatagoriesCard';
import { useState } from 'react';
import CatagoryQuestions from './CatagoryQuestions/CatagoryQuestions';

const FaqCatagories = ({ catagories }) => {
  const [activeCatagory, setActiveCatagory] = useState(0);

  const selectedCatagory = catagories.find(
    (data) => data.id === activeCatagory
  );

  return (
    <div className="bg-custom-black py-10">
      <div className="flex justify-center">
        <p className="text-center text-white font-Poppins font-light text-sm">
          or choose a category to quickly find a answer you need
        </p>
      </div>
      <div className=" flex justify-center flex-wrap">
        {catagories.map(({ id, Icon, name }) => {
          return (
            <div
              className="mb-8 p-3"
              key={id}
              onClick={() => {
                setActiveCatagory(id);
              }}>
              <FaqCatagoriesCard Icon={Icon} id={id} name={name} />
            </div>
          );
        })}
      </div>
      {selectedCatagory !== undefined && (
        <div className="mt-12">
          <CatagoryQuestions
            name={selectedCatagory.name}
            questions={selectedCatagory.Questions}
          />
        </div>
      )}
    </div>
  );
};

export default FaqCatagories;
