import Navbar from '../Navbar/Navbar';
import NavbarMobile from '../Navbar/NavbarMobile/NavbarMobile';
import {
  isLargeScreen,
  isTabletOrMobile,
} from '../../utlils/constants/constants';
import { useMediaQuery } from 'react-responsive';
import ChildSearchBar from './ChildSearchBar';
import '../../styles/Faq.css';

const ChildHeroSection = ({
  image,
  isSearchBar,
  title,
  navlist,
  navProperties,
}) => {
  const isLarge = useMediaQuery(isLargeScreen);
  const isTablet = useMediaQuery(isTabletOrMobile);

  return (
    <div className="bg-custom-yellow">
      {/* Header Section */}
      <div>
        {isLarge || !isTablet ? (
          <Navbar navbarList={navlist} navbarProperties={navProperties} />
        ) : (
          <NavbarMobile navbarList={navlist} navbarProperties={navProperties} />
        )}
      </div>
      <div className="h-72">
        <div
          className={`flex justify-end  mt-12 -mb-44 ${
            !isSearchBar && 'pb-24 lg:pb-7'
          }`}>
          <img alt="" src={image} />
        </div>
        <div
          className={` flex  flex-wrap justify-center place-items-center gap-10 ${
            !isSearchBar && 'p-10'
          }`}>
          {isLarge && <div className="line-faq"></div>}
          <h1 className="text-4xl text-center text-black font-bold">{title}</h1>
          {isLarge && <div className="line-faq"></div>}
        </div>
        {/* Search Section */}
        {isSearchBar && (
          <ChildSearchBar placeholder={'Type your question here..'} />
        )}
      </div>
    </div>
  );
};

export default ChildHeroSection;
