import { PlusSignIcon } from '../../../assets/Icons/Icons';
import '../../../styles/admin.css';

const DashboardHeader = ({ title, isRightButton = false, onClickHandler }) => {
  return (
    <div>
      <div className="text-white font-Poppins flex justify-between place-items-end">
        <label>{title}</label>
        {isRightButton && (
          <button
            className="bg-custom-yellow flex gap-3 font-Poppins px-6 py-2 rounded text-black font-bold add-bottom place-items-center"
            onClick={onClickHandler}>
            <PlusSignIcon />
            Add New
          </button>
        )}
      </div>
      <div className="pt-2">
        <hr></hr>
      </div>
    </div>
  );
};

export default DashboardHeader;
