import { useState } from 'react';
import { UploadIcon } from '../../../assets/images/svg';

const MultiImageUpload = () => {
  const [noOfFiles, setNoOfFiles] = useState(0);

  const handleImages = (event) => {
    setNoOfFiles(event.target.files.length);
  };

  return (
    <>
      <div>
        <label
          className="flex gap-2 pt-1 pb-4 font-Poppins text-white text-sm"
          htmlFor="AddMultiImages">
          <UploadIcon />
          <u>Upload Image</u>
        </label>
        <input
          accept="image/*"
          className="hidden"
          id="AddMultiImages"
          multiple
          onChange={handleImages}
          type="file"></input>
      </div>
      <div className="font-Poppins text-custom-yellow text-sm">
        {<p>{noOfFiles} Images Selected</p>}
      </div>
    </>
  );
};

export default MultiImageUpload;
