import { EmailIcon } from '../../assets/images/svg';
import { RightArrow } from '../../assets/Icons/Icons';

const Footer = () => {
  const articles = [
    {
      id: 1,
      title: 'Title of Article-1',
    },
    {
      id: 2,
      title: 'Title of Article-2',
    },
    {
      id: 3,
      title: 'Title of Article-3',
    },
    {
      id: 4,
      title: 'Title of Article-4',
    },
    {
      id: 5,
      title: 'Title of Article-5',
    },
  ];

  return (
    <div className="bg-custom-yellow">
      <div className="bg-custom-yellow container flex justify-start flex-wrap">
        <div className="lg:p-20 py-5 px-10 lg:w-1/2">
          <div>
            <h1 className="text-5xl text-custom-black font-semibold">LOGO</h1>
          </div>
          <div className="text-justify py-7 ">
            <p>
              (About us) Lorem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incididunt ut labore et dolore magna
              aliqua.
            </p>
          </div>
          <div className="flex space-x-2 place-items-center">
            <EmailIcon /> <h1>support@evhub.com</h1>
          </div>
        </div>
        <div className="lg:p-20 py-5 px-10 w-full lg:w-1/2">
          <div className="flex  space-x-2  place-items-center">
            <h1 className="text-4xl text-custom-black font-bold">EV STORIES</h1>
            <RightArrow fill="#041018" height="20" width="35" />
          </div>
          <div className="py-3">
            {articles.map(({ title }, index) => {
              return (
                <div key={index}>
                  <div className="py-3 text-justify flex justify-between  place-items-center">
                    <p className="font-light text-left text-sm font-Poppins">
                      {title}
                    </p>
                    <RightArrow fill="#041018" height="15" width="15" />
                  </div>
                  <hr className="border-1 border-black"></hr>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="bg-footer-yellow text-center">
        <h1 className="p-2 font-Poppins text-sm text-black">
          COPYRIGHT © 2021. ALL RIGHTS RESERVED{' '}
        </h1>
      </div>
    </div>
  );
};

export default Footer;
