export const HomeNavbarList = [
  {
    id: 1,
    title: 'Home',
    path: '/',
    dropdownList: [],
  },
  {
    id: 2,
    title: 'Working',
    dropdownList: [
      {
        id: 1,
        content: 'Electric Car',
        subList: [],
      },
      {
        id: 2,
        content: 'Electric Bike',
        subList: [],
      },
      {
        id: 3,
        content: 'Electric Cycle',
        subList: [],
      },
      {
        id: 4,
        content: 'Electric Scooter',
        subList: [],
      },
    ],
  },
  {
    id: 3,
    title: 'Vehicles',
    dropdownList: [
      {
        id: 1,
        content: 'Electric Car',
        subList: [],
      },
      {
        id: 2,
        content: 'Electric Bike',
        subList: [],
      },
      {
        id: 3,
        content: 'Electric Cycle',
        subList: [],
      },
      {
        id: 4,
        content: 'Electric Scooter',
        subList: [],
      },
    ],
  },
  {
    id: 4,
    title: 'Charging Station',
    dropdownList: [
      {
        id: 1,
        content: 'Charging Station Near me',
        path: '/evcharging',
        subList: [],
      },
      {
        id: 2,
        content: 'Own a Franchise',
        path: '/evcharging',
        subList: [],
      },
    ],
  },
  {
    id: 5,
    title: 'Components',
    dropdownList: [
      {
        id: 1,
        content: 'EV Battery',
        subList: [
          {
            id: 1.1,
            content: 'Lithium Ion Battery',
            path: '/evstories',
          },
          {
            id: 1.2,
            content: 'Hydrogen Cell',
            path: '/evstories',
          },
        ],
      },
      {
        id: 2,
        content: 'Accessories',
        subList: [],
      },
    ],
  },
  {
    id: 6,
    title: 'EV Guide',
    dropdownList: [
      {
        id: 1,
        content: 'EV Stories',
        path: '/evstories',
        subList: [],
      },
      {
        id: 2,
        content: 'EV Stocks',
        path: '/evstories',
        subList: [],
      },
      {
        id: 3,
        content: 'How to',
        path: '/evstories',
        subList: [],
      },
      {
        id: 4,
        content: 'EV Buying Guide',
        path: '/evstories',
        subList: [],
      },
    ],
  },
];

export const HomeNavbarProperties = {
  titleActiveColor: 'text-custom-yellow',
  titleInactiveColor: 'text-white',
  dropDownActiveArrowColor: '#E4B43F',
  dropDownInactiveArrowColor: 'white',
  dropDownBackgroundColor: 'bg-dropdown-background',
  dropDownActiveElementColor: 'bg-custom-yellow',
  dropDownTextColor: 'text-custom-black',
  subDropDownArrowColor: 'black',
  rightButtonBgColor: 'bg-custom-yellow',
  rightButtonTitleColor: 'text-black',
  rightButtonTitle: 'FAQ',
  navOpenIconColor: 'white',
  navCloseIcon: 'white',
  mobileNavBackground: 'bg-custom-black',
  mobileActiveTextColor: 'text-custom-black',
  mobileInActiveTextColor: 'text-white',
  mobSubDropDownArrowColor: 'white',
};

export const SubHomeNavbarProperties = {
  titleActiveColor: 'text-white',
  titleInactiveColor: 'text-custom-black',
  dropDownActiveArrowColor: 'white',
  dropDownInactiveArrowColor: 'black',
  dropDownBackgroundColor: 'bg-dropdown-background text-custom-black',
  dropDownActiveElementColor: 'bg-custom-black text-white',
  dropDownTextColor: 'text-white',
  subDropDownArrowColor: 'white',
  rightButtonBgColor: 'bg-custom-black',
  rightButtonTitleColor: 'text-white',
  rightButtonTitle: 'FAQ',
  navOpenIconColor: 'black',
  navCloseIcon: 'black',
  mobileNavBackground: 'bg-custom-yellow',
  mobileActiveTextColor: 'text-white',
  mobileInActiveTextColor: 'text-custom-black',
  mobSubDropDownArrowColor: 'white',
};
