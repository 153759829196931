import { useState } from 'react';
import CustomSelect from '../CustomSelect/CustomSelect';
const CreateFormTitleInput = ({
  handleSelectVehicle,
  optionsList,
  selectedType,
  placeholderText = 'Enter Something Here',
  defaultSelectText = 'Select Type',
  isSelect = false,
  handleTitleInput,
}) => {
  const [showOptionList, setShowOptionList] = useState(false);

  return (
    <div className="flex lg:justify-between md:justify-between justify-evenly flex-wrap">
      <div className={`title-input ${isSelect ? 'w-3/4' : 'w-full'}`}>
        <input
          className="w-full"
          onChange={handleTitleInput}
          placeholder={placeholderText}
          type="text"></input>
      </div>
      {isSelect && (
        <div onClick={() => setShowOptionList(!showOptionList)}>
          <CustomSelect
            defaultText={defaultSelectText}
            handleSelectVehicle={handleSelectVehicle}
            optionsList={optionsList}
            selectedItem={selectedType}
            showOptionList={showOptionList}
          />
        </div>
      )}
    </div>
  );
};

export default CreateFormTitleInput;
