import UploadImage from '../UploadImage/UploadImage';
import { PlusSignIcon } from '../../../assets/Icons/Icons';
import { UploadIcon, View360Icon } from '../../../assets/images/svg';
import { useState } from 'react';

const CreateFormUploadImagesSection = ({ handleImageArray }) => {
  const [addImage, setAddImage] = useState(4);

  return (
    <div className="pt-6 flex justify-between">
      <div className="flex justify-start place-items-center lg:overflow-x-hidden md:overflow-x-hidden overflow-x-scroll">
        {[...Array(addImage)].map((_, i) => {
          return (
            <div className="p-4" key={i}>
              <UploadImage handleImageArray={handleImageArray} />
            </div>
          );
        })}
        <div className="px-10" onClick={() => setAddImage(addImage + 1)}>
          <PlusSignIcon
            height="20"
            strokeColor="white"
            strokeWidth="2"
            width="20"
          />
        </div>
      </div>
      <div className="p-4">
        <button className="flex gap-2 font-Poppins text-sm font-thin focus:outline-none bg-search-background px-5 py-2 rounded ">
          <UploadIcon />
          Upload Images
        </button>
        <div className="pt-4">
          <button className="flex gap-2 font-Poppins text-sm text-white font-thin border border-white focus:outline-none  px-9 py-2 rounded">
            <View360Icon />
            Add 360*
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateFormUploadImagesSection;
