import { PlusSignIcon } from '../../../assets/Icons/Icons';
import MultiImageUpload from '../MultiImageUpload/MultiImageUpload';
import SelectColor from '../SelectColor/SelectColor';
import { useState } from 'react';

const CreateFormColorPickerSection = () => {
  const [addColorPicker, setAddColorPicker] = useState(4);
  return (
    <div>
      <p className="pt-4 font-Poppins font-medium text-white text-sm">
        Select Color Variants (Upload vehicle image of that respective color)
      </p>
      <div className="flex gap-4 place-items-center">
        {[...Array(addColorPicker)].map((_, i) => {
          return (
            <div className="px-4">
              <SelectColor />
              <MultiImageUpload />
            </div>
          );
        })}

        <div
          className="p-2 cursor-pointer"
          onClick={() => setAddColorPicker(addColorPicker + 1)}>
          <PlusSignIcon
            height="20"
            strokeColor="white"
            strokeWidth="2"
            width="20"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateFormColorPickerSection;
