export const isXsScreen = { query: '(max-width: 411px)' };
export const isSmallScreen = { query: '(max-width: 599px)' };
export const isMediumScreen = {
  query: '(min-width: 600px) and (max-width: 1199px)',
};
export const isLargeScreen = { query: '(min-width: 1024px)' };
export const isBigScreen = { query: '(min-width: 1824px)' };
export const isTabletOrMobile = { query: '(max-width: 1020px)' };
export const isPortrait = { query: '(orientation: portrait)' };
export const isRetina = { query: '(min-resolution: 2dppx)' };
