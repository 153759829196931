import '../../../styles/EvChargingStation.css';
import { SearchIcon } from '../../../assets/images/svg';
import Select from '../Select/Select';
import { useState } from 'react';

const Search = () => {
  const [isDropdown, setIsDropdown] = useState(false);
  return (
    <>
      <div className="flex justify-center">
        <div className=" lg:w-2/3 w-full">
          <div className="search-input-home  bg-search-background flex place-items-center">
            <div onClick={() => setIsDropdown(!isDropdown)}>
              <Select isDropDownOpen={isDropdown} />
            </div>
            <input
              className=" w-full text-black placeholder-black leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Type your address"
              type="text"
            />
            <div className="p-2">
              <SearchIcon />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex justify-center relative z-30">
        {isDropdown && (
          <div className="lg:w-2/3 w-full dropdown-background">
            <div className="flex justify-center  flex-wrap relative z-30">
              {evChargingStations.map(({ id, city, Icon }) => {
                return (
                  <div className="p-6 flex flex-col justify-center" key={id}>
                    <div>{<Icon />}</div>
                    <div className="text-center text-sm font-Poppins">
                      <h1>{city}</h1>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div> */}
    </>
  );
};

export default Search;
