import {
  AhmedabadIcon,
  BangaloreIcon,
  ChandigarhIcon,
  ChennaiIcon,
  DelhiIcon,
  HyderabadIcon,
  KochiIcon,
  KolkataIcon,
  MumbaiIcon,
  PuneIcon,
} from '../../assets/images/background_images/Cities/index';

export const evChargingStations = [
  {
    id: 1,
    city: 'Ahmedabad',
    Icon: AhmedabadIcon,
  },
  {
    id: 2,
    city: 'Bangalore',
    Icon: BangaloreIcon,
  },
  {
    id: 3,
    city: 'Chandigarh',
    Icon: ChandigarhIcon,
  },
  {
    id: 4,
    city: 'Chennai',
    Icon: ChennaiIcon,
  },
  {
    id: 5,
    city: 'Delhi',
    Icon: DelhiIcon,
  },
  {
    id: 6,
    city: 'Hyderabad',
    Icon: HyderabadIcon,
  },
  {
    id: 7,
    city: 'Kochi',
    Icon: KochiIcon,
  },
  {
    id: 8,
    city: 'Kolkata',
    Icon: KolkataIcon,
  },
  {
    id: 9,
    city: 'Mumbai',
    Icon: MumbaiIcon,
  },
  {
    id: 10,
    city: 'Pune',
    Icon: PuneIcon,
  },
];
