import FeatureCard from './FeatureCard/FeatureCard';

const FeaturesSection = ({ cardProperties }) => {
  return (
    <div className="container">
      <div className="custom-black">
        <div className="flex flex-wrap justify-center p-6 lg:space-x-6">
          {cardProperties.map(({ id, title, icon, content }) => {
            return (
              <FeatureCard Icon={icon} key={id} title={title}>
                {content}
              </FeatureCard>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
