import { Helmet } from 'react-helmet';
import DashboardHeader from '../../../components/Admin/Header/DashboardHeader';
import DashboardSearch from '../../../components/Admin/Header/DashboardSearch';
import ListCards from '../../../components/Admin/ListCards/ListCards';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { componentsOptionsList } from '../constants';

const MyComponents = () => {
  const [selectedType, setSelectedType] = useState('');
  const navigate = useNavigate();

  const handleSelectType = (label) => {
    setSelectedType(label);
  };

  const navigateToAddCreateComponent = () =>
    navigate('/dashboard/mycomponents/createComponent');
  return (
    <div>
      <Helmet>
        <title>My Components</title>
        <meta content="My Components" name="description" />
      </Helmet>
      <div className="pt-10 pr-10 pl-10">
        <DashboardHeader
          isRightButton={true}
          onClickHandler={navigateToAddCreateComponent}
          title={'My Components'}
        />
        <DashboardSearch
          handleSelectType={handleSelectType}
          isRightButton={true}
          optionsList={componentsOptionsList}
          selectDefaultText={'Select Type'}
          selectedType={selectedType}
          title={'Search Components'}
        />
        <div className="pt-10">
          <ListCards />
        </div>
      </div>
    </div>
  );
};

export default MyComponents;
