import { Route, Routes } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Sidebar from '../../../components/Admin/Sidebar/Sidebar';
import { SideBarMenuList } from '../constants';
import { isLargeScreen } from '../../../utlils/constants/constants';
import MyVehichles from '../MyVehicles/MyVehicles';
import CreateVehicles from '../MyVehicles/CreateVehicles';
import MyFaq from '../MyFaq/MyFaq';
import MyEvStories from '../MyEvStories/MyEvStories';
import MyComponents from '../MyComponents/MyComponents';
import CreateFaq from '../MyFaq/CreateFaq';
import CreateEvStory from '../MyEvStories/CreateEvStory';
import CreateComponent from '../MyComponents/CreateComponent';

const Dashboard = () => {
  const largeScreen = useMediaQuery(isLargeScreen);

  return (
    <div className="bg-custom-black grid grid-cols-5 h-screen ">
      {largeScreen && (
        <div className="lg:col-span-1">
          <Sidebar menuList={SideBarMenuList} />
        </div>
      )}
      <div className="lg:col-span-4 col-span-5">
        <Routes>
          <Route element={<MyVehichles />} path="/" />
          <Route element={<MyVehichles />} path="/myvehicles" />
          <Route element={<MyFaq />} path="/myfaqs" />
          <Route element={<CreateFaq />} path="/myfaqs/createFaq" />
          <Route
            element={<CreateEvStory />}
            path="/myevstories/createEvStory"
          />
          <Route element={<MyEvStories />} path="/myevstories" />
          <Route element={<MyComponents />} path="/mycomponents" />
          <Route
            element={<CreateComponent />}
            path="/mycomponents/createComponent"
          />
          <Route
            element={<CreateVehicles />}
            path="/myvehicles/createVehicles"
          />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
