import { Link, useLocation } from 'react-router-dom';
import SidemenuButton from './SidemenuButton';

const Sidebar = ({ menuList }) => {
  const { pathname } = useLocation();

  return (
    <div className=" h-screen bg-ev-story-background  border-r-2">
      <div className=" text-center p-4">
        <label className="font-Poppins text-2xl font-thin text-center p-4">
          LOGO
        </label>
      </div>
      <div className="grid justify-center mt-5">
        {menuList.map(({ id, icon, title, path }) => {
          return (
            <div className="pt-2" key={id}>
              <Link to={path}>
                <SidemenuButton
                  Icon={icon}
                  active={pathname.includes(path)}
                  title={title}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
