import ListCards from '../../../components/Admin/ListCards/ListCards';

import DashboardHeader from '../../../components/Admin/Header/DashboardHeader';
import DashboardSearch from '../../../components/Admin/Header/DashboardSearch';
import { Helmet } from 'react-helmet';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faqOptionsList } from '../constants';

const MyFaq = () => {
  const [selectedType, setSelectedType] = useState('');
  const navigate = useNavigate();

  const handleSelectType = (label) => {
    setSelectedType(label);
  };

  const navigateToAddFaq = () => navigate('/dashboard/myfaqs/createFaq');

  return (
    <div>
      <Helmet>
        <title>My FAQ'S</title>
        <meta content="My FAQ'S" name="description" />
      </Helmet>
      <div className="pt-10 pr-10 pl-10">
        <DashboardHeader
          isRightButton={true}
          onClickHandler={navigateToAddFaq}
          title={"My FAQ'S"}
        />
        <DashboardSearch
          handleSelectType={handleSelectType}
          isRightButton={true}
          optionsList={faqOptionsList}
          selectDefaultText={'Catagory'}
          selectedType={selectedType}
          title={'Search FAQ'}
        />
        <div className="pt-10">
          <ListCards />
        </div>
      </div>
    </div>
  );
};

export default MyFaq;
