import { NoImageIcon } from '../../../assets/images/svg';
import '../../../styles/EvStories.css';
import styled from 'styled-components';

export let EvStoriesCardStyle = styled.div`
  padding: 1.35rem;
  border-radius: 0.625rem;
  height: 27.875rem;
  transition: transform 1s;
  transition-timing-function: ease-out;

  &:nth-child(${(p) => p.lastIndex}) {
    transform: rotateX(28deg);
    opacity: 0.6;
  }
  .stories-hero-images {
    background-color: #c4c4c4;
    width: 100%;
    height: 193px;
  }
  .image-stories-card {
    background-color: #c4c4c4;
    height: 193px;
  }
`;

const EvStoriesCard = ({ image, title, url, desc, lastIndex }) => {
  const description = desc.match(/.{1,100}(\s|$)/g)[0];
  return (
    <EvStoriesCardStyle
      className={`bg-ev-stories-card-background`}
      lastIndex={lastIndex}>
      {image ? (
        <img alt="" className="stories-hero-images" src={image} />
      ) : (
        <div className="image-stories-card flex justify-center place-items-center">
          <NoImageIcon />
        </div>
      )}
      <div className="pt-6">
        <h1 className="text-white font-Poppins text-left text-lg font-bold">
          {title}
        </h1>
        <p className="text-white font-Poppins text-left break-word pt-4 text-sm ">
          {description}...
          <a
            className="text-custom-yellow underline font-bold"
            href={url}
            rel="noreferrer"
            target="_blank">
            Read more..
          </a>
        </p>
      </div>
    </EvStoriesCardStyle>
  );
};

export default EvStoriesCard;
