import { useState } from 'react';
import { NavCloseIcon, NavOpenIcon } from '../../../assets/Icons/Icons';
import NavbarMobileElement from './NavbarMobileElement';

const NavbarMobile = ({ navbarList, navbarProperties }) => {
  const [isCrossClick, setIsCrossClick] = useState(false);

  const {
    rightButtonTitle,
    rightButtonBgColor,
    rightButtonTitleColor,
    navOpenIconColor,
    navCloseIcon,
    mobileNavBackground,
  } = navbarProperties;

  return (
    <div>
      <div
        className={`${
          isCrossClick && mobileNavBackground
        } bg-opacity-90 flex justify-between place-items-center`}>
        <div>
          {isCrossClick && (
            <div className="relative flex-start p-6 top-6  z-50">
              <button
                className={`${rightButtonBgColor} ${rightButtonTitleColor} px-10 py-2.5 font-medium text-center  faq-button  text-md`}>
                {rightButtonTitle}
              </button>
            </div>
          )}
        </div>
        <div className="relative  p-6 top-6 z-50">
          {isCrossClick ? (
            <div onClick={() => setIsCrossClick(!isCrossClick)}>
              <NavCloseIcon color={navCloseIcon} />
            </div>
          ) : (
            <div onClick={() => setIsCrossClick(!isCrossClick)}>
              <NavOpenIcon color={navOpenIconColor} />
            </div>
          )}
        </div>
      </div>
      {isCrossClick && (
        <div
          className={`${mobileNavBackground} w-screen h-screen z-40 absolute bg-opacity-90`}>
          {navbarList.map(({ id, title, dropdownList }) => {
            return (
              <div key={id}>
                <NavbarMobileElement
                  dropdownList={dropdownList}
                  navbarProperties={navbarProperties}
                  title={title}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NavbarMobile;
