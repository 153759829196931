import { useState, useEffect } from 'react';
import EvStoriesCard from '../EvStoriesCard/EvStoriesCard';
import { useMediaQuery } from 'react-responsive';
import {
  isTabletOrMobile,
  isLargeScreen,
  isSmallScreen,
} from '../../../utlils/constants/constants';
import '../../../styles/EvStories.css';
import {
  HallowLeftArrowIcon,
  HallowRightArrowIcon,
} from '../../../assets/images/svg';

const EvStoriesCardsSlider = ({ stories }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(4);
  const [length, setLength] = useState(stories.length);

  var show;

  const tablet = useMediaQuery(isTabletOrMobile);
  const largeScreen = useMediaQuery(isLargeScreen);
  const mobile = useMediaQuery(isSmallScreen);

  if (largeScreen) {
    show = 4;
  } else if (tablet && !mobile) {
    show = 2;
  } else if (mobile) {
    show = 1;
  }

  useEffect(() => {
    setLength(stories.length);
  }, [stories]);

  const next = () => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 1);
      setLastIndex((prevState) => prevState + 1);
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
      setLastIndex((prevState) => prevState - 1);
    }
  };

  return (
    <div className="bg-custom-black">
      <div className="carousel-content-wrapper w-full 2xl:w-6/7">
        <div
          className={`carousel-content show-${show}`}
          style={{
            transform:
              currentIndex === 0
                ? `translateX(-${currentIndex * (100 / show)}%)`
                : `translateX(calc(-${currentIndex * (100 / show)}% + ${
                    currentIndex * -39
                  }px))`,
          }}>
          {stories.map(({ id, image, description, title, url }) => {
            return (
              <EvStoriesCard
                desc={description}
                image={image}
                key={id}
                lastIndex={lastIndex}
                title={title}
                url={url}
              />
            );
          })}
        </div>
      </div>
      <div className="flex justify-center space-x-8 p-7">
        <div
          className={`rounded-full h-14 w-14 cursor-pointer flex justify-center place-items-center ${
            currentIndex <= 0 ? 'slider-nav-left' : 'slider-nav-right'
          }`}
          onClick={prev}>
          <HallowLeftArrowIcon />
        </div>
        <div
          className={`rounded-full h-14 w-14 cursor-pointer flex justify-center place-items-center ${
            currentIndex === stories.length - 1
              ? 'slider-nav-left'
              : 'slider-nav-right'
          }`}
          onClick={next}>
          <HallowRightArrowIcon />
        </div>
      </div>
    </div>
  );
};

export default EvStoriesCardsSlider;
