import '../../../styles/EvChargingStation.css';
import { DropDownArrow } from '../../../assets/Icons/Icons';
import { LocationIcon } from '../../../assets/images/svg';

const Select = ({ isDropDownOpen }) => {
  return (
    <div
      className={`${
        isDropDownOpen ? 'select-element-dropdown-open' : 'select-element'
      }   bg-custom-black p-1 cursor-pointer`}>
      <div className="flex justify-center place-items-center">
        <LocationIcon />
        <div>
          <DropDownArrow fill="white" />
        </div>
      </div>
    </div>
  );
};

export default Select;
