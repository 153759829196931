import car from '../../assets/images/EvChargeingInParking.png';
import evStats from '../../assets/images/EV-Stats.png';
import evSales from '../../assets/images/ev-sales.png';

const StorySection = () => {
  return (
    <div className="bg-custom-black px-10">
      <div className="bg-ev-story-background h-auto rounded-3xl border-2 border-white font-Poppins text-sm font-normal p-10 w-full text-left">
        <div className="grid">
          <p>
            One of the most common and trending statements that we have been
            hearing in recent times is "EVs are the future of transportation.
            "But is it a reality?
          </p>
          <p className="pt-5">
            Are we, as humans, ready to shift ourselves from the traditional
            fuel-powered vehicles to the ones powered from batteries?
          </p>
          <p className="pt-5">
            Let us take a deep dive into the various factors which ensure that
            indeed Electric Vehicles are the future, and also learn about the
            recent developments in the EV ecosystem.
          </p>
        </div>
        <div className="pt-10">
          <h1 className="font-Poppins font-bold text-lg bg-custom-yellow w-max">
            What are EVs?
          </h1>
          <p className="py-3">
            First things first, we should know <i>what EVs actually are?</i>
          </p>
          <p>
            Electric vehicles (or EVs) are vehicles that operate on an electric
            motor, powered with the help of Cells or Batteries. They can be
            eitherpartially or fully juicedby electric motors. Unlike the ones
            powered by fossil fuels, EVs are very eco-friendly as they donot
            produce any smoke which can be harmful to the environment.
          </p>
        </div>
        <div className="grid justify-items-center">
          <img alt="car" src={car}></img>
        </div>
        <div className="font-Poppins">
          <h1 className="font-bold text-lg bg-custom-yellow w-max">
            Different types of EVs
          </h1>
          <dl>
            <dt>
              <div className="rounded bg-custom-yellow w-2 h-2"></div>
              Pure-electric vehicles:
            </dt>
            <dd>
              <p>
                A vehicle juiced entirely by electric motors is a pure-electric
                vehicle. They can obtain the electricity needed from a battery,
                solar panel, or fuel cells.
              </p>
            </dd>
            <dt>
              <div className="rounded bg-custom-yellow w-2 h-2"></div>
              Hybrid-Electric vehicles:
            </dt>
            <dd>
              <p>
                A vehicle that combines both a conventional internal combustion
                engine and an electric motor is called a Hybrid-Electric
                vehicle. This helps in better fuel economy and adds an extended
                range to the vehicle
              </p>
            </dd>
            <dt>
              <div className="rounded bg-custom-yellow w-2 h-2"></div>Plug-in
              Electric vehicles:
            </dt>
            <dd>
              <p>
                These are the most talked about and the best example of an
                Electric vehicle. They can be recharged from any external source
                like a wall socket, and powered by the rechargeable batteries
                inside them.
              </p>
            </dd>
          </dl>
          <p className="pt-3 mr-10">
            Now, since we have a better understanding of what EVs are all about,
            let us discuss “Why they will be the future of commute”?
          </p>
        </div>
        <div className="pt-10 font-Poppins factors">
          <h1 className="font-bold">1. Environmental Impact</h1>
          <p>
            The fast-growing global vehicle fleet is a major contributor to air
            pollution and climate change; globally, the transport sector is
            responsible for nearly a quarter of global greenhouse gas emissions.
            In India only, nearly 30% of the pollution is caused by vehicle
            pollution and these stats will only rise with time. These vehicles
            emit an enormous amount of carbon dioxide and other greenhouse gases
            which is a leading factor of global warming.
          </p>
          <p>
            In the Paris climate summit, India is set to update its 2030 climate
            targets under the Paris Agreement. India is considering cutting down
            almost by half the amount of greenhouse gases produced for every
            dollar of economic activity by the end of the decade.
          </p>
          <p>
            To achieve this, it is very crucial to find a substitute for fossil
            fuel vehicles. And that’s when EVs come in as the best option. They
            are very Eco-Friendly. Electric vehicles produce zero emissions
            while running because there is no fuel to be burnt in the first
            place. But they need electricity to operate which in turn will emit
            harmful gases when produced, assuming coal is being burnt.
          </p>
          <p>
            Even thenresearch done by the European Energy Agency says that the
            carbon emissions of an electric car are around 17-30% lower than
            driving a petrol or diesel car.
          </p>
          <p>
            Thus adapting Electric vehicles will have a major impact on global
            warming in a positive way.
          </p>
          <h1>2. Rapid exhaustion of fossil fuels</h1>
          <p>
            According to studies by ourworldindata.org, if we keep on using
            fossil fuels at this pace, we will run out of fuel in less than 50
            years from now. And as the supply declines the cost will skyrocket
            makingit nearly impossible for us to use gasoline vehicles as a mode
            of transportation. This makes it very necessary to have some other
            power sources for our vehicles.
          </p>
          <p>
            Electric vehicles usually use lithium-ion batteries as a power
            source. Besides this, research is continuously going on to identify
            some other affordable substitute elements to make batteries.
          </p>
          <p>
            In India, start-ups like Log9 materials have been continuously
            working on developing new substitutes for lithium-ion batteries.
            Recently, they managed to build batteries powered by aluminium fuel
            cells which provide good outputs like lithium-ion batteries.
          </p>
          <h1>3. Less Maintenance Cost</h1>
          <p>
            One of the biggest day-to-day savings is the cost of fuel. A 2018
            study from the University of Michigan found that electric vehicles
            cost less than half to operate as gas-powered cars. The average cost
            to operate an EV in the United States is $485 per year, while the
            average for a gasoline-powered vehicle is $1,117.
          </p>
          <p>
            Today in India, the cost per km for an average car is around
            Rs.5-8/km while an EV costs around Rs.1/km only. This is a very big
            chunk of money saved, and for a developing country like India, it
            plays a very major role.
          </p>
          <p>
            Unlike internal combustion engine vehicles, electric cars are more
            reliable with the fact that they have very few moving parts. The
            electric motor that drives electric vehicles is very simple in
            design, containing perhaps half a dozen moving parts, which leads to
            less wear and tear of components. And therefore it requires
            considerably less maintenance than conventional vehicles. No oil to
            change, no gaskets to replace, electric cars do not have internal
            combustion engines, so these costs disappear.
          </p>
          <h1>4. Role of Governing Bodies</h1>
          <p>
            Undoubtedly,governmental bodies around the globe are doing a great
            job on the adaptation of EVs. Governments around the world are
            making attractive policies so that people adapt Electric vehicles as
            their primary mode of commute. European countries stand on the top
            when we talk about EV adaptation, followed by theUnited States and
            the list goes on and on. Some of the policies include:
          </p>
          <div className="pt-5">
            <ul>
              <li>• Providing Subsidies on Buying EVs.</li>
              <li>• Eliminating the Road Tax.</li>
              <li>• Developing Good Charging Infrastructure.</li>
              <li>
                • Promoting New Players in the Market to increase competition.
              </li>
              <li>
                • Financially aiding Startups dealing with EV Technology. And
                many more of such policies.
              </li>
            </ul>
          </div>

          <p>
            India is not very far behind in this list. The pace of EV adaptation
            in the country is speeding with each passing day. Huge credit goes
            to the government for making policies to Increase the adaptation
            rate for EVs.
          </p>
          <p>
            The numbers of EVs on the road have also been increasing at a rather
            good pace with the number of EVs on the road standing at 155,400
            growing at around 20% year-on-year in the year 2019/20. Governmental
            policies like the Faster Adoption and Manufacturing of (Hybrid &)
            Electric Vehicles in India (FAME) which provide subsidies for EV
            production and charging infrastructure come as a cherry on the cake.
          </p>
          <p>
            Furthermore, the Indian government too has provided subsidies on
            Electric Vehicles and is continuously working on improving the
            charging infrastructure.
          </p>
        </div>
        <div className="pt-5 font-Poppins flex justify-around  md:flex-nowrap flex-wrap">
          <div className="ev-scenario lg:w-2/3">
            <h1 className="bg-custom-yellow font-bold text-lg lg:w-max md:w-max">
              What is the current scenario of EVs in the World?
            </h1>
            <p>
              One very obvious name which pops into our mind when we talk about
              EVs is Tesla. Tesla manufactures only Electric vehicles and still
              has a market cap more than any other car manufacturing company on
              the globe.
            </p>
            <p>
              Founded in 2003 by Elon Musk, today tesla is the tycoon of the
              electric vehicle market. Currently, with a market cap of around $1
              trillion, tesla is the 6th most valuable company by market cap in
              the year 2020-21.
            </p>
            <p>
              In the car manufacturing companies, Tesla ranks No.1, beating
              giants like Toyota, Volkswagen, Honda, and many more. This stat
              alone shows that EVs are not going anywhere instead they will be
              leading the future on its shoulder.
            </p>
          </div>
          <div>
            <img alt="ev-stats" src={evStats}></img>
          </div>
        </div>
        <div className="ev-sales font-Poppins">
          <p>
            Many industry observers believe we have already passed the tipping
            point where sales of electric vehicles (EVs) will very rapidly cross
            petrol and diesel cars.
          </p>
          <p>
            Big players in the Vehicle ecosystem are also showing rapid interest
            in Electric vehicles. Jaguar plans to sell only electric cars from
            2025, Volvo from 2030, and last week the British sports-car company
            Lotus said it would follow suit, selling only electric models from
            2028.
          </p>
          <p>
            General Motors says it will make only electric vehicles by 2035,
            Ford says all vehicles sold in Europe will be electric by 2030 and
            Volkswagen says 70% of its sales will be electric by 2030. Studies
            suggest that by 2025, 20% of all the vehicles sold globally will be
            electric.
          </p>
          <div className="justify-center pt-4 flex">
            <img alt="ev sales" src={evSales}></img>
          </div>
        </div>
        <div className="pt-5 ev-future font-Poppins">
          <h1 className="bg-custom-yellow font-bold text-lg lg:w-max md:w-max">
            What are the Present and Future of EVs in India?
          </h1>
          <p>
            India is rapidly adapting to the electric vehicle trend. With giants
            like TATA launching new Electric vehicles within a gap of six
            months, the future of EVs does look bright in the country.
          </p>
          <p>
            According to the reports, Overall EV sales for Tata climbed 234%
            over April-September 2020; Tata Motors accounts for over 70% of
            electric cars sold in India.In fact, at mid-stage in the ongoing
            fiscal year, Tata has already crossed the 4,219 units it sold last
            year. While the Nexon EV, with 3,618 units, is India’s best-selling
            EV and commands a 58% market share, the Tigor EV with 801 units has
            a 13% market share and is placed third on the EV chart.
          </p>
          <p>
            Ola too entered the two-seater EV segment with the launch of the Ola
            scooter. On its first two days only, Ola managed to sell scooters
            worth Rs.1100 Crores making it very clear that they will lead
            India’s electric 2 wheeler ecosystem.
          </p>
        </div>
        <div className="pt-5 conclusion font-Poppins">
          <h1 className="bg-custom-yellow font-bold text-lg lg:w-max md:w-max">
            Conclusion
          </h1>
          <p>
            Before wrapping things up, let us revisit the key points that we
            have covered in this article. We talked about:
          </p>
          <ul className="pt-5">
            <li>• What EVs are?</li>
            <li>• Types of EVs.</li>
            <li>• Why EVs are the future?</li>
            <li>• Current scenario of EVs in the world.</li>
            <li>• Present and Future of EVs in India.</li>
          </ul>
          <p>
            We hope that as a reader, you got a good insight on “Why Electric
            Vehicles will be the future?”
          </p>
          <p>
            Are you interested in buying an Electric vehicle in the near future?
            We would love to hear your views on the concerned topic and hope you
            enjoyed reading this article.
          </p>
        </div>
        <h1 className="font-Poppins font-bold pt-5">-By Aditya Jha</h1>
      </div>
    </div>
  );
};

export default StorySection;
