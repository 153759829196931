import { Helmet } from 'react-helmet';
import AddEvStory from '../../../components/Admin/AddEvStory/AddEvStory';

const CreateEvStory = () => {
  return (
    <>
      <Helmet>
        <title>Create Ev Story</title>
        <meta content="Create Ev Story" name="description" />
      </Helmet>
      <div className="lg:col-span-4 col-span-5">
        <AddEvStory />
      </div>
    </>
  );
};

export default CreateEvStory;
