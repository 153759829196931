import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ListCards from '../../../components/Admin/ListCards/ListCards';
import DashboardHeader from '../../../components/Admin/Header/DashboardHeader';
import DashboardSearch from '../../../components/Admin/Header/DashboardSearch';
import { vehiclesOptionsList } from '../constants';
import '../../../styles/admin.css';

const MyVehichles = () => {
  const [selectedType, setSelectedType] = useState('');
  const navigate = useNavigate();

  const handleSelectType = (label) => {
    setSelectedType(label);
  };

  const navigateToAddVehichle = () =>
    navigate('/dashboard/myvehicles/createVehicles');

  return (
    <>
      <Helmet>
        <title>My Vehicles</title>
        <meta content="My Vehicles" name="description" />
      </Helmet>
      <div className="pt-10 pr-10 pl-10">
        <DashboardHeader
          isRightButton={true}
          onClickHandler={navigateToAddVehichle}
          title={'My Vehicles'}
        />
        <DashboardSearch
          handleSelectType={handleSelectType}
          isRightButton={true}
          optionsList={vehiclesOptionsList}
          selectDefaultText={'Select Type'}
          selectedType={selectedType}
          title={'Search Vehicles'}
        />
        <div className="pt-10">
          <ListCards />
        </div>
      </div>
    </>
  );
};

export default MyVehichles;
