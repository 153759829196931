import MailBox from '../../assets/images/background_images/Mailbox-box.svg';
import { SendIcon } from '../../assets/images/svg';

const FeedbackSection = () => {
  return (
    <div className="bg-custom-black">
      <div className="flex flex-wrap justify-center">
        <div className="px-10 lg:w-1/2 w-full py-10">
          <div className="bg-custom-yellow p-1 w-48">
            <label className="font-Poppins text-sm font-bold">
              Share your Views with us-
            </label>
          </div>
          <div className="feedback-input pt-10">
            <input
              className="w-full font-Poppins focus:outline-none focus:shadow-outline"
              placeholder="Enter your name here"
              type="text"></input>
          </div>
          <div className="feedback-input py-4">
            <input
              className="w-full font-Poppins focus:outline-none focus:shadow-outline"
              placeholder="Enter your email id here"
              type="text"></input>
          </div>
          <div className="feedback-input">
            <textarea
              className="w-full font-Poppins focus:outline-none focus:shadow-outline"
              placeholder="Comments/Suggestions..."></textarea>
          </div>
          <div className="pt-9">
            <button className="bg-custom-yellow p-2 justify-center rounded-lg font-Poppins font-bold text-lg flex gap-4 w-full">
              Submit
              <SendIcon />
            </button>
          </div>
        </div>
        <div className="lg:w-1/2 grid place-items-center">
          <img alt="mailBox" src={MailBox}></img>
        </div>
      </div>
    </div>
  );
};

export default FeedbackSection;
