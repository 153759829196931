import ElectricVehicles from '../../components/ElectricVehicles/ElectricVehicles';
import EvChargingStation from '../../components/EvChargingStation/EvChargingStation';
import EvStocks from '../../components/EvStocks/EvStocks';
import EvStories from '../../components/EvStories/EvStories';
import FaqSection from '../../components/FaqSection/FaqSection';
import FeaturesSection from '../../components/FeaturesSection/FeaturesSection';
import LandingPage from '../../components/LandingPage/LandingPage';
import Footer from '../../components/Footer/Footer';
import {
  cardProperties,
  electricVehiclesImages,
  Ev_stories,
  faqQuestion,
} from './constants';

const Home = () => {
  return (
    <div className="bg-custom-black">
      <LandingPage />
      <FeaturesSection cardProperties={cardProperties} />
      <ElectricVehicles images={electricVehiclesImages} />
      <EvChargingStation />
      <EvStocks />
      <EvStories stories={Ev_stories} />
      <FaqSection questions={faqQuestion} />
      <Footer />
    </div>
  );
};

export default Home;
