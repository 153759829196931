import { DropDownArrow } from '../../../assets/Icons/Icons';
import { useState } from 'react';
import '../../../styles/Navbar.css';

const NavbarMobileElement = ({ title, dropdownList, navbarProperties }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [subActiveIndex, setSubActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const {
    titleActiveColor,
    titleInactiveColor,
    dropDownActiveArrowColor,
    dropDownInactiveArrowColor,
    dropDownActiveElementColor,
    mobileInActiveTextColor,
    mobileActiveTextColor,
    mobSubDropDownArrowColor,
  } = navbarProperties;

  const subDropdown = (dropdownList) => {
    return (
      <ul>
        {dropdownList.map(({ id, content, subList }) => {
          return (
            <li
              className={`${
                subActiveIndex === id
                  ? dropDownActiveElementColor
                  : mobileInActiveTextColor
              }  font-Poppins text-lg p-2`}
              key={id}
              onClick={() => setSubActiveIndex(id)}>
              {content}
            </li>
          );
        })}
      </ul>
    );
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
    setActiveIndex(0);
  };

  return (
    <div>
      <div className="flex justify-between" onClick={() => handleClick()}>
        <div
          className={`${
            isOpen ? titleActiveColor : titleInactiveColor
          }  text-lg font-Poppins mobile-nav-mainList`}>
          <label>{title}</label>
        </div>
        {dropdownList.length > 0 && (
          <div className="mobile-nav-arrow">
            {isOpen ? (
              <DropDownArrow fill={dropDownActiveArrowColor} />
            ) : (
              <DropDownArrow fill={dropDownInactiveArrowColor} />
            )}
          </div>
        )}
      </div>
      <div>
        {isOpen && dropdownList.length > 0 && (
          <div className="mobile-nav-dropdown">
            <ul>
              {dropdownList.map(({ id, content, subList }) => {
                return (
                  <div key={id}>
                    <li
                      className={`${
                        activeIndex === id
                          ? `${dropDownActiveElementColor} ${mobileActiveTextColor}`
                          : mobileInActiveTextColor
                      }  font-Poppins text-lg p-2`}
                      onClick={() => setActiveIndex(id)}>
                      <div className="flex justify-between ">
                        {content}
                        {subList.length > 0 && (
                          <div className="mobile-nav-sub-arrow">
                            <DropDownArrow fill={mobSubDropDownArrowColor} />
                          </div>
                        )}
                      </div>
                    </li>
                    <div
                      className="px-3"
                      style={{ display: activeIndex === id ? '' : 'none' }}>
                      {subDropdown(subList)}
                    </div>
                  </div>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarMobileElement;
