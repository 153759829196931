import { DropDownArrow, SubDropDownArrow } from '../../../assets/Icons/Icons';
import '../../../styles/Navbar.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const NavElement = ({ title, dropdownList, path, navbarProperties }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [subMenuList, setSubMenuList] = useState([]);

  const {
    titleActiveColor,
    titleInactiveColor,
    dropDownActiveArrowColor,
    dropDownInactiveArrowColor,
    dropDownBackgroundColor,
    dropDownActiveElementColor,
    dropDownTextColor,
    subDropDownArrowColor,
  } = navbarProperties;

  const SubDropdown = () => {
    return (
      <ul>
        {subMenuList.map(({ id, content, path }) =>
          path && path !== undefined ? (
            <Link to={path}>
              <li
                className={`${
                  activeIndex === id
                    ? dropDownActiveElementColor
                    : dropDownBackgroundColor
                } nav-element-padding py-2.5 nav-element-border font-Poppins text-sm`}
                key={id}
                onMouseLeave={() => setActiveIndex(0)}
                onMouseMove={() => setActiveIndex(id)}>
                {content}
              </li>
            </Link>
          ) : (
            <li
              className={`${
                activeIndex === id
                  ? dropDownActiveElementColor
                  : dropDownBackgroundColor
              } nav-element-padding py-2.5 nav-element-border font-Poppins text-sm`}
              key={id}
              onMouseLeave={() => setActiveIndex(0)}
              onMouseMove={() => setActiveIndex(id)}>
              {content}
            </li>
          )
        )}
      </ul>
    );
  };

  const MainDropdown = ({ id, subList, title }) => {
    return (
      <li
        className={`${
          activeIndex === id
            ? dropDownActiveElementColor
            : dropDownBackgroundColor
        } flex ${
          subList.length > 0 ? 'if-sub-list' : 'nav-element-padding'
        } space-x-2 place-items-center cursor-pointer  py-2.5 nav-element-border font-Poppins text-sm ${dropDownTextColor}`}
        key={id}
        onMouseEnter={() => {
          if (subList.length > 0) {
            setIsSubMenuOpen(true);
            setSubMenuList(subList);
            setActiveIndex(id);
          }
        }}
        onMouseLeave={() => {
          setActiveIndex(0);
        }}
        onMouseMove={() => {
          setActiveIndex(id);
          if (activeIndex !== id) {
            setIsSubMenuOpen(false);
            setSubMenuList([]);
          }
        }}>
        {title}
        {subList.length > 0 && (
          <div className="arrow-padding">
            <SubDropDownArrow fill={subDropDownArrowColor} />
          </div>
        )}
      </li>
    );
  };

  return (
    <div
      onMouseEnter={() => setIsMenuOpen(true)}
      onMouseLeave={() => {
        setIsMenuOpen(false);
        setIsSubMenuOpen(false);
      }}>
      {dropdownList.length > 0 ? (
        <div
          className={`${
            isMenuOpen ? titleActiveColor : titleInactiveColor
          } flex space-x-2 text-sm font-Poppins cursor-pointer place-items-center`}>
          <label className="cursor-pointer">{title}</label>
          <DropDownArrow
            fill={
              isMenuOpen ? dropDownActiveArrowColor : dropDownInactiveArrowColor
            }
          />
        </div>
      ) : (
        <Link to={path}>
          <div
            className={`${titleInactiveColor} flex space-x-2 text-sm font-Poppins cursor-pointer place-items-center hover:${titleActiveColor}`}>
            <label className="cursor-pointer">{title}</label>
          </div>
        </Link>
      )}
      <div className="absolute flex flex-row-reverse">
        {/* sub drop down menu */}
        {isSubMenuOpen && (
          <div
            className="z-40 mt-2"
            onMouseLeave={() => {
              setIsSubMenuOpen(false);
              setSubMenuList([]);
            }}>
            <SubDropdown />
          </div>
        )}

        {/* drop down menu */}
        {isMenuOpen && (
          <div className="mt-2 z-20 text-left">
            <ul>
              {dropdownList.map(({ id, content, path, subList }, index) =>
                path && path !== undefined ? (
                  <Link to={path}>
                    <MainDropdown
                      id={id}
                      key={index}
                      subList={subList}
                      title={content}
                    />
                  </Link>
                ) : (
                  <MainDropdown
                    id={id}
                    key={index}
                    subList={subList}
                    title={content}
                  />
                )
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavElement;
