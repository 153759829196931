import { type, type as loopedType } from '@camwiegert/typical';
import React, { useRef, useEffect } from 'react';
import '../../styles/TypeingAnimation.css';

const TypeingAnimation = ({ steps, loop, wrapper }) => {
  const typicalRef = useRef(null);
  const Component = wrapper;

  useEffect(() => {
    if (loop === Infinity) {
      type(typicalRef.current, ...steps, loopedType);
    } else if (typeof loop === 'number') {
      type(typicalRef.current, ...Array(loop).fill(steps).flat());
    } else {
      type(typicalRef.current, ...steps);
    }
  });

  return (
    <Component className="typicalWrapper text-custom-yellow" ref={typicalRef} />
  );
};

export default TypeingAnimation;
