import ChildHeroSection from '../../components/ChildHeroSection/ChildHeroSection';
import stories from '../../assets/images/background_images/STORIES.png';
import EvStoriesGrid from '../../components/EvStoriesGrid/EvStoriesGrid';
import { Ev_stories } from '../Home/constants';
import Footer from '../../components/Footer/Footer';
import {
  HomeNavbarList,
  SubHomeNavbarProperties,
} from '../../utlils/constants/navbarConstant';

const EvStoriesPage = () => {
  return (
    <div className="bg-custom-black">
      <ChildHeroSection
        image={stories}
        isSearchBar={false}
        navProperties={SubHomeNavbarProperties}
        navlist={HomeNavbarList}
        title="Read EV Stories and articles"
      />
      <div>
        <EvStoriesGrid articles={Ev_stories} />
      </div>
      <Footer />
    </div>
  );
};

export default EvStoriesPage;
