import '../../../styles/FeaturesCard.css';
const FeatureCard = ({ Icon, title, children }) => {
  return (
    <div className="pt-3 pl-3">
      <div className="relative z-30 ml-6 -mb-5 border-4 border-black rounded-full w-14 h-14 icon-circle ">
        <div className="flex justify-center p-4">{<Icon />}</div>
      </div>
      <div className=" bg-card-background rounded-2xl features-card">
        <div className="p-6 text-white">
          <label className="text-xl font-medium text-white">{title}</label>
          <p className="py-5 text-sm font-light">{children}</p>
        </div>
      </div>
    </div>
  );
};

export default FeatureCard;
