export const RightArrow = ({ width, height, fill }) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 23 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M-2.43634e-07 8.56488C-2.50812e-07 8.31252 0.10025 8.07049 0.278697 7.89205C0.457144 7.7136 0.699171 7.61335 0.951534 7.61335L19.1578 7.61335L13.1689 1.6263C12.9902 1.44763 12.8898 1.2053 12.8898 0.952617C12.8898 0.699935 12.9902 0.457604 13.1689 0.278932C13.3475 0.100259 13.5899 -0.000117261 13.8425 -0.000117278C14.0952 -0.000117295 14.3376 0.100259 14.5162 0.278932L22.1285 7.8912C22.2171 7.97959 22.2874 8.08459 22.3354 8.20019C22.3834 8.31579 22.408 8.43972 22.408 8.56488C22.408 8.69004 22.3834 8.81397 22.3354 8.92957C22.2874 9.04517 22.2171 9.15018 22.1285 9.23857L14.5162 16.8508C14.3376 17.0295 14.0952 17.1299 13.8425 17.1299C13.5899 17.1299 13.3475 17.0295 13.1689 16.8508C12.9902 16.6722 12.8898 16.4298 12.8898 16.1771C12.8898 15.9245 12.9902 15.6821 13.1689 15.5035L19.1578 9.51642L0.951534 9.51642C0.699171 9.51642 0.457144 9.41617 0.278697 9.23772C0.10025 9.05927 -2.36455e-07 8.81725 -2.43634e-07 8.56488Z"
        fill={fill}
        fillRule="evenodd"
      />
    </svg>
  );
};

export const SearchIcon = ({ width, height, fill }) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 30 30"
      width={width}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 22.5C14.7187 22.4995 16.8735 21.7568 18.6212 20.39L24.1162 25.885L25.8837 24.1175L20.3888 18.6225C21.7562 16.8746 22.4995 14.7193 22.5 12.5C22.5 6.98625 18.0137 2.5 12.5 2.5C6.98625 2.5 2.5 6.98625 2.5 12.5C2.5 18.0138 6.98625 22.5 12.5 22.5ZM12.5 5C16.6362 5 20 8.36375 20 12.5C20 16.6363 16.6362 20 12.5 20C8.36375 20 5 16.6363 5 12.5C5 8.36375 8.36375 5 12.5 5Z"
        fill={fill}
      />
    </svg>
  );
};
export const DropDownArrow = ({ fill }) => {
  return (
    <svg
      fill="none"
      height="8"
      viewBox="0 0 10 8"
      width="10"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.500043 0.500188H9.50004C9.59116 0.500474 9.68048 0.525605 9.75838 0.572877C9.83628 0.620149 9.89981 0.687771 9.94214 0.768465C9.98447 0.849158 10.004 0.939868 9.99859 1.03083C9.9932 1.12179 9.9631 1.20956 9.91154 1.28469L5.41154 7.78469C5.22504 8.05419 4.77604 8.05419 4.58904 7.78469L0.0890427 1.28469C0.0369551 1.20972 0.0064098 1.1219 0.000725321 1.03079C-0.00495916 0.939678 0.0144347 0.848749 0.0567997 0.767885C0.0991647 0.68702 0.162881 0.619311 0.241025 0.572116C0.319169 0.524921 0.408753 0.500044 0.500043 0.500188Z"
        fill={fill}
      />
    </svg>
  );
};

export const SubDropDownArrow = ({ fill }) => {
  return (
    <svg
      fill="none"
      height="10"
      viewBox="0 0 8 10"
      width="8"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-0.000299952 9.49923L-0.000300346 0.499226C-1.47244e-05 0.408105 0.0251165 0.318788 0.0723887 0.240888C0.11966 0.162988 0.187282 0.0994552 0.267976 0.0571283C0.34867 0.0148013 0.43938 -0.00471657 0.530341 0.000674552C0.621303 0.00606662 0.709071 0.0361636 0.7842 0.0877259L7.2842 4.58773C7.5537 4.77423 7.5537 5.22323 7.2842 5.41022L0.7842 9.91023C0.709229 9.96231 0.621416 9.99286 0.530303 9.99854C0.439189 10.0042 0.348261 9.98483 0.267396 9.94247C0.186532 9.9001 0.118823 9.83639 0.0716281 9.75824C0.0244326 9.6801 -0.000443953 9.59052 -0.000299952 9.49923Z"
        fill={fill}
      />
    </svg>
  );
};

export const NavCloseIcon = ({ color }) => {
  return (
    <svg
      fill="none"
      height="26"
      viewBox="0 0 26 26"
      width="26"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M2 2L24.6274 24.6274" stroke={color} strokeWidth="3" />
      <path d="M24.6274 2L2.00002 24.6274" stroke={color} strokeWidth="3" />
    </svg>
  );
};

export const NavOpenIcon = ({ color }) => {
  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 32 25"
      width="32"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1H32" stroke={color} strokeWidth="2" />
      <path d="M0 12.0342H32" stroke={color} strokeWidth="2" />
      <path d="M0 23.0693H16.5517" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export const PlusSignIcon = ({
  height = 12,
  width = 12,
  strokeWidth = 2,
  strokeColor = 'black',
}) => {
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.4668 0V16" stroke={strokeColor} strokeWidth={strokeWidth} />
      <path
        d="M16 8.47058L-9.23872e-07 8.47058"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export const BoldIcon = ({ fill = 'black', height = '1em', width = '1em' }) => {
  return (
    <svg fill={fill} height={height} viewBox="0 0 1024 1024" width={width}>
      <path d="M697.8 481.4c33.6-35 54.2-82.3 54.2-134.3v-10.2C752 229.3 663.9 142 555.3 142H259.4c-15.1 0-27.4 12.3-27.4 27.4v679.1c0 16.3 13.2 29.5 29.5 29.5h318.7c117 0 211.8-94.2 211.8-210.5v-11c0-73-37.4-137.3-94.2-175.1zM328 238h224.7c57.1 0 103.3 44.4 103.3 99.3v9.5c0 54.8-46.3 99.3-103.3 99.3H328V238zm366.6 429.4c0 62.9-51.7 113.9-115.5 113.9H328V542.7h251.1c63.8 0 115.5 51 115.5 113.9v10.8z" />
    </svg>
  );
};

export const ItalicIcon = ({
  fill = 'black',
  height = '1em',
  width = '1em',
}) => {
  return (
    <svg fill={fill} height={height} viewBox="0 0 1024 1024" width={width}>
      <path d="M798 160H366c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h181.2l-156 544H229c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h432c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8H474.4l156-544H798c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z" />
    </svg>
  );
};

export const UnderlineIcon = ({
  fill = 'black',
  height = '1em',
  width = '1em',
}) => {
  return (
    <svg fill={fill} height={height} viewBox="0 0 1024 1024" width={width}>
      <path d="M824 804H200c-4.4 0-8 3.4-8 7.6v60.8c0 4.2 3.6 7.6 8 7.6h624c4.4 0 8-3.4 8-7.6v-60.8c0-4.2-3.6-7.6-8-7.6zm-312-76c69.4 0 134.6-27.1 183.8-76.2C745 602.7 772 537.4 772 468V156c0-6.6-5.4-12-12-12h-60c-6.6 0-12 5.4-12 12v312c0 97-79 176-176 176s-176-79-176-176V156c0-6.6-5.4-12-12-12h-60c-6.6 0-12 5.4-12 12v312c0 69.4 27.1 134.6 76.2 183.8C377.3 701 442.6 728 512 728z" />
    </svg>
  );
};

export const HeadingOneIcon = ({
  fill = 'black',
  height = '1em',
  width = '1em',
}) => {
  return (
    <svg fill={fill} height={height} viewBox="0 0 16 16" width={width}>
      <path d="M8.637 13V3.669H7.379V7.62H2.758V3.67H1.5V13h1.258V8.728h4.62V13h1.259zm5.329 0V3.669h-1.244L10.5 5.316v1.265l2.16-1.565h.062V13h1.244z" />
    </svg>
  );
};

export const HeadingTwoIcon = ({
  fill = 'black',
  height = '1em',
  width = '1em',
}) => {
  return (
    <svg fill={fill} height={height} viewBox="0 0 16 16" width={width}>
      <path d="M7.638 13V3.669H6.38V7.62H1.759V3.67H.5V13h1.258V8.728h4.62V13h1.259zm3.022-6.733v-.048c0-.889.63-1.668 1.716-1.668.957 0 1.675.608 1.675 1.572 0 .855-.554 1.504-1.067 2.085l-3.513 3.999V13H15.5v-1.094h-4.245v-.075l2.481-2.844c.875-.998 1.586-1.784 1.586-2.953 0-1.463-1.155-2.556-2.919-2.556-1.941 0-2.966 1.326-2.966 2.74v.049h1.223z" />
    </svg>
  );
};

export const BlockQuoteIcon = ({
  fill = 'black',
  height = '1em',
  width = '1em',
}) => {
  return (
    <svg fill={fill} height={height} viewBox="0 0 24 24" width={width}>
      <path d="M20.309 17.708C22.196 15.66 22.006 13.03 22 13V5a1 1 0 00-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 001 1h3.078a2.89 2.89 0 01-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C11.19 15.66 10.999 13.03 10.993 13V5a1 1 0 00-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 001 1h3.078a2.89 2.89 0 01-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V20h1c2.783 0 4.906-.771 6.309-2.292z" />
    </svg>
  );
};

export const OrderedListIcon = ({
  fill = 'black',
  height = '1em',
  width = '1em',
}) => {
  return (
    <svg fill={fill} height={height} viewBox="0 0 1024 1024" width={width}>
      <path d="M920 760H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0-568H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H336c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM216 712H100c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h72.4v20.5h-35.7c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h35.7V838H100c-2.2 0-4 1.8-4 4v34c0 2.2 1.8 4 4 4h116c2.2 0 4-1.8 4-4V716c0-2.2-1.8-4-4-4zM100 188h38v120c0 2.2 1.8 4 4 4h40c2.2 0 4-1.8 4-4V152c0-4.4-3.6-8-8-8h-78c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4zm116 240H100c-2.2 0-4 1.8-4 4v36c0 2.2 1.8 4 4 4h68.4l-70.3 77.7a8.3 8.3 0 00-2.1 5.4V592c0 2.2 1.8 4 4 4h116c2.2 0 4-1.8 4-4v-36c0-2.2-1.8-4-4-4h-68.4l70.3-77.7a8.3 8.3 0 002.1-5.4V432c0-2.2-1.8-4-4-4z" />
    </svg>
  );
};

export const UnorderedListIcon = ({
  fill = 'black',
  height = '1em',
  width = '1em',
}) => {
  return (
    <svg fill={fill} height={height} viewBox="0 0 1024 1024" width={width}>
      <path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0z" />
    </svg>
  );
};
