import { useState } from 'react';

import '../../styles/HomeFaqSection.css';

const QuestionBlock = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        className="bg-question-block-background flex justify-between place-items-center p-3 cursor-pointer rounded-2xl"
        onClick={() => handleOpen()}>
        <h1>{question}</h1>
        <div
          className={`${
            isOpen ? 'opened' : 'closed'
          }  place-items-center mt-1 mr-2`}>
          {/* <QuestionPlusIcon /> */}
          <div className="horizontal"></div>
          <div className="vertical"></div>
        </div>
      </div>
      {isOpen && (
        <div
          className={`border-b-2 border-l-2 border-r-2 mr-3 ml-3 p-3 border-white  rounded-b-2xl`}>
          <p className={`text-white`}>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default QuestionBlock;
