import {
  isLargeScreen,
  isTabletOrMobile,
} from '../../utlils/constants/constants';
import { useMediaQuery } from 'react-responsive';
import Navbar from '../Navbar/Navbar';
import NavbarMobile from '../Navbar/NavbarMobile/NavbarMobile';
import {
  HomeNavbarList,
  HomeNavbarProperties,
} from '../../utlils/constants/navbarConstant';

const StoryHeroSection = () => {
  const isLarge = useMediaQuery(isLargeScreen);
  //const isMedium = useMediaQuery(isMediumScreen);
  const isTablet = useMediaQuery(isTabletOrMobile);
  return (
    <div className="bg-ev-story-hero-background">
      <div>
        {isLarge || !isTablet ? (
          <Navbar
            navbarList={HomeNavbarList}
            navbarProperties={HomeNavbarProperties}
          />
        ) : (
          <NavbarMobile
            navbarList={HomeNavbarList}
            navbarProperties={HomeNavbarProperties}
          />
        )}
      </div>
      <div className="grid h-screen place-content-center">
        <div className="text-white font-Poppins text-2xl font-bold flex justify-center flex-wrap text-center p-3">
          <p>
            <span className="text-custom-yellow">“EVs are the future”–</span>a
            Reality or a Hoax?
          </p>
        </div>
        <div className="text-white font-Poppins text-sm font-normal flex justify-center mt-9">
          <p>Home &gt; Ev Stories &gt; EVs are the future...</p>
        </div>
      </div>
    </div>
  );
};

export default StoryHeroSection;
