import '../../../styles/Faq.css';

const FaqCatagoriesCard = ({ id, Icon, name }) => {
  return (
    <div>
      <div className="catagories-card w-32 h-32 flex justify-center place-items-center">
        {<Icon />}
      </div>
      <div className="flex justify-center -mt-12">
        <p>{name}</p>
      </div>
    </div>
  );
};

export default FaqCatagoriesCard;
