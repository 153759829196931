import stocks from '../../../src/assets/images/stocks.svg';
import '../../styles/EvStocks.css';
import { useMediaQuery } from 'react-responsive';
import { RightArrow } from '../../assets/Icons/Icons';
import { isXsScreen } from '../../utlils/constants/constants';

const EvStocks = () => {
  const isXs = useMediaQuery(isXsScreen);

  return (
    <div className="container">
      <div className="bg-custom-black">
        <div className="flex flex-wrap justify-center place-items-center mb-12">
          <div className="mt-24 lg:w-1/2 lg:stocks-image p-4">
            <img alt="" src={stocks}></img>
          </div>
          <div className="lg:w-1/2  lg:stocks-text stocks-text-md-sm">
            <h1 className="font-Poppins text-white text-4xl mb-20">
              Give your Portfolio a fly with{' '}
              <span className="font-Poppins text-custom-yellow">EV Stocks</span>
            </h1>
            <div className="text-sm font-Poppins text-white text-justify">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p>
            </div>
            {isXs ? (
              <div className="flex mt-10 justify-center ">
                <button className="bg-custom-yellow  flex  justify-center place-items-center p-2 px-10 gap-3 font-Poppins text-custom-black border-2 border-custom-yellow font-bold rounded">
                  Start Investing
                  <RightArrow fill="#041018" height="15" width="15" />
                </button>
              </div>
            ) : (
              <div className="flex mt-10 justify-start ">
                <button className="bg-custom-yellow  flex  justify-center place-items-center p-3 px-20 gap-5 font-Poppins text-custom-black border-2 border-custom-yellow font-bold rounded">
                  Start Investing
                  <RightArrow fill="#041018" height="15" width="15" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvStocks;
