import '../../../styles/admin.css';

const SidemenuButton = ({ Icon, title, active = false }) => {
  return (
    <div
      className={`${
        active && 'bg-custom-yellow side-menu-button font-bold'
      } flex justify-start gap-6 rounded-lg w-44 p-2 font-Poppins font-thin cursor-pointer`}>
      <div>{<Icon />}</div>
      <label>{title}</label>
    </div>
  );
};

export default SidemenuButton;
