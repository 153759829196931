import UploadImage from '../UploadImage/UploadImage';
import { UploadIcon } from '../../../assets/images/svg';
import CreateFormHeader from '../CreateFormHeader/CreateFormHeader';
import CreateFormTitleInput from '../CreateFormTitleInput/CreateFormTitleInput';
import CreateFormDescriptionInput from '../CreateFormDescriptionInput/CreateFormDescriptionInput';
const AddEvStory = () => {
  return (
    <div className="pt-10 pr-10 pl-10">
      <CreateFormHeader />
      <div className="pt-6 scroll-style">
        <form>
          <CreateFormTitleInput
            isSelect={false}
            placeholderText={'Title of Story'}
          />
          <CreateFormDescriptionInput
            placeholderText={'Description of Story'}
          />
          <div className="pt-6">
            <label className="font-Poppins text-sm text-white font-thin">
              Upload Cover Photo
            </label>
            <div className="pt-6">
              <UploadImage className="upload-cover-photo" isCoverPhoto={true} />
            </div>
          </div>
          <div className="p-6 flex justify-center">
            <button className="flex gap-2 font-Poppins text-sm font-thin focus:outline-none bg-search-background px-5 py-2 rounded ">
              <UploadIcon />
              Upload Image
            </button>
          </div>
          <div className="flex justify-center gap-3 mt-16 mb-10">
            <button className="font-Poppins text-sm font-thin focus:outline-none bg-custom-yellow px-10 py-2 rounded">
              Save
            </button>
            <button className="font-Poppins text-sm text-white font-thin border border-white focus:outline-none bg-custom-gray px-10 py-2 rounded ">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEvStory;
