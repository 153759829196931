import { useState } from 'react';
import { Helmet } from 'react-helmet';
import CreateFormDescriptionInput from '../../../components/Admin/CreateFormDescriptionInput/CreateFormDescriptionInput';
import CreateFormHeader from '../../../components/Admin/CreateFormHeader/CreateFormHeader';
import CreateFormTitleInput from '../../../components/Admin/CreateFormTitleInput/CreateFormTitleInput';
import { TextEditor } from '../../../components/TextEditor';
import { faqOptionsList } from '../constants';

const CreateFaq = () => {
  const [selectedType, setSelectedType] = useState('');

  const handleSelectVehicle = (label) => {
    setSelectedType(label);
  };
  return (
    <>
      <Helmet>
        <title>Create FAQ</title>
        <meta content="Create Faq" name="description" />
      </Helmet>
      <div className="lg:col-span-4 col-span-5">
        <div className="pt-10 pr-10 pl-10">
          <CreateFormHeader />
          <div className="pt-6 scroll-style">
            <form>
              <CreateFormTitleInput
                defaultSelectText="Catagory"
                handleSelectVehicle={handleSelectVehicle}
                isSelect={true}
                optionsList={faqOptionsList}
                selectedType={selectedType}
              />
              <CreateFormDescriptionInput
                placeholderText={'Description of FAQ'}
              />
              <TextEditor />
              <div className="flex justify-center gap-x-6 mt-24 mb-10">
                <button className="font-Poppins text-sm font-thin focus:outline-none bg-custom-yellow px-12 py-2 rounded">
                  Save
                </button>
                <button className="font-Poppins text-sm text-white font-thin border border-white focus:outline-none bg-custom-gray px-10 py-2 rounded ">
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateFaq;
