import { PlusIcon } from '../../assets/images/svg';
import EvStoriesCardsSlider from './EvStoriesCardsSlider/EvStoriesCardsSlider';
import '../../styles/EvStories.css';

const EvStories = ({ stories }) => {
  return (
    <div className="place-content-center">
      <div className="flex justify-center place-items-center lg:space-x-3 mb-16 mt-32">
        <PlusIcon />
        <h1 className=" text-3xl font-bold text-white text-center font-Poppins ">
          EV Stories
        </h1>
        <PlusIcon />
      </div>
      <div class="container">
        <EvStoriesCardsSlider stories={stories} />
      </div>
    </div>
  );
};

export default EvStories;
