const InputElement = ({ dataKey = 1 }) => {
  return (
    <div className="title-input w-full pb-2">
      <input
        className="w-full"
        placeholder={`Add Feature ${dataKey}`}
        type="text"></input>
    </div>
  );
};

export default InputElement;
