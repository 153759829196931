import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditorComponent } from './style';

export const TextEditor = () => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
      ['clean'],
    ],
  };

  const formats = [
    'background',
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'color',
    'clean',
  ];
  return (
    <EditorComponent>
      <ReactQuill formats={formats} modules={modules} theme="snow" />
    </EditorComponent>
  );
};
