import { faqCatagories } from './constants';
import faqImage from '../../assets/images/background_images/FAQ.png';
import FaqCatagories from '../../components/FaqCatagories/FaqCatagories';
import ChildHeroSection from '../../components/ChildHeroSection/ChildHeroSection';
import Footer from '../../components/Footer/Footer';
import {
  HomeNavbarList,
  SubHomeNavbarProperties,
} from '../../utlils/constants/navbarConstant';

const FAQ = () => {
  return (
    <div>
      <ChildHeroSection
        image={faqImage}
        isSearchBar={true}
        navProperties={SubHomeNavbarProperties}
        navlist={HomeNavbarList}
        title="Find EV Related Answers Here"
      />
      <FaqCatagories catagories={faqCatagories} />
      <Footer />
    </div>
  );
};
export default FAQ;
