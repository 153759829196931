import { SearchIcon } from '../../../assets/Icons/Icons';
import '../../../styles/admin.css';
import CustomSelect from '../CustomSelect/CustomSelect';
import { useState } from 'react';

const DashboardSearch = ({
  optionsList,
  title,
  isRightButton = false,
  selectDefaultText,
  selectedType,
  handleSelectType,
}) => {
  const [showOptionList, setShowOptionList] = useState(false);

  return (
    <div className="flex lg:justify-between md:justify-between  justify-evenly pt-6 flex-wrap">
      <div className="admin-search w-2/3 flex place-items-center  px-6">
        <input
          className="font-Poppins text-sm"
          placeholder={`Search ${title}`}
          type="text"></input>
        <SearchIcon fill="white" height="20" width="20" />
      </div>
      {isRightButton && (
        <div onClick={() => setShowOptionList(!showOptionList)}>
          <CustomSelect
            defaultText={selectDefaultText}
            handleSelectVehicle={handleSelectType}
            optionsList={optionsList}
            selectedItem={selectedType}
            showOptionList={showOptionList}
          />
        </div>
      )}
    </div>
  );
};

export default DashboardSearch;
