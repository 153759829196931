import { PlusSignIcon } from '../../../assets/Icons/Icons';
import { SketchPicker } from 'react-color';
import { useState } from 'react';

const SelectColor = () => {
  const [color, setColor] = useState('#000000');
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <>
      <div className="p-4">
        <div
          className="color-picker cursor-pointer z-10 relative"
          onClick={() => setShowColorPicker(!showColorPicker)}
          style={{ backgroundColor: `${color}` }}>
          {color === '#000000' && (
            <PlusSignIcon
              height="20"
              strokeColor="white"
              strokeWidth="2"
              width="20"
            />
          )}
        </div>
      </div>
      {showColorPicker && (
        <div className="z-30 relative -mt-20 ml-14">
          <SketchPicker color={color} onChange={(e) => setColor(e.hex)} />
        </div>
      )}
    </>
  );
};

export default SelectColor;
