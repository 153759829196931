import { PlusIcon } from '../../assets/images/svg/index';
import Search from './Search/Search';
import { RightArrow } from '../../assets/Icons/Icons';

const EvChargingStation = () => {
  return (
    <div className="bg-cover bg-ev-charging-background">
      <div className=" flex justify-center place-items-center lg:space-x-3 py-12">
        <PlusIcon />
        <h1 className=" text-3xl text-center font-bold text-white font-Poppins">
          EV CHARGING STATIONS
        </h1>
        <PlusIcon />
      </div>
      <div className="container">
        <div className="mb-40 mt-20 p-2 relative z-10">
          <Search />
        </div>
        <div className="relative z-10">
          <div className="mt-10 flex justify-center mb-10 p-2">
            <p className="text-white text-center font-Poppins text-sm">
              Do you want to own a franchise of EV Charging Station? or want to
              know more about Charging Stations
            </p>
          </div>
          <div className="flex justify-center pb-20">
            <button className=" bg-opacity-0  flex  justify-center place-items-center p-3 px-20 gap-5 font-Poppins text-custom-yellow border-2 border-custom-yellow font-bold rounded">
              Know more
              <RightArrow fill="#E4B43F" height="15" width="15" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvChargingStation;
