import { SearchIcon } from '../../assets/Icons/Icons';
const ChildSearchBar = ({ placeholder, isSelect = false }) => {
  return (
    <div className="flex justify-center mt-24 pb-5">
      <div className=" lg:w-2/3 w-full p-3">
        <div className="search-input bg-search-background flex place-items-center p-1">
          <input
            className=" w-full text-black placeholder-black leading-tight focus:outline-none focus:shadow-outline"
            placeholder={placeholder}
            type="text"
          />
          <div className="p-2 search_icon_border">
            <SearchIcon fill="white" height="25" width="25" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChildSearchBar;
