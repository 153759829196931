import { useState } from 'react';
import NavElement from './NavElement/NavElement';
import { DesktopNavbar, DesktopNavbarIcon } from './NavbarStyled';
import '../../styles/Navbar.css';

const Navbar = ({ navbarList, navbarProperties }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const { rightButtonTitle, rightButtonBgColor, rightButtonTitleColor } =
    navbarProperties;
  return (
    <div className="flex items-center justify-end gap-10 py-5">
      <DesktopNavbar
        className={`${
          isNavOpen ? 'nav-open' : 'nav-close'
        } items-center flex justify-end mr-3 space-x-11`}
        navMenuLength={navbarList.length}>
        {navbarList.map(({ id, title, path, dropdownList }) => {
          return (
            <NavElement
              dropdownList={dropdownList}
              id={id}
              key={id}
              navbarProperties={navbarProperties}
              path={path}
              title={title}
            />
          );
        })}
        <div className="mr-28 nav-button">
          <button
            className={`${rightButtonBgColor} ${rightButtonTitleColor} px-5 py-2.5 font-medium text-center  faq-button  text-md`}>
            {rightButtonTitle}
          </button>
        </div>
      </DesktopNavbar>
      <div className="mr-10 cursor-pointer">
        <DesktopNavbarIcon
          className={isNavOpen ? 'open' : ''}
          id="nav-icon1"
          onClick={() => setIsNavOpen(!isNavOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </DesktopNavbarIcon>
      </div>
    </div>
  );
};

export default Navbar;
