import {
  CarIcon,
  SettingsIcon,
  FaqIcon,
  PowerIcon,
} from '../../assets/images/svg';
import car from '../../assets/images/svg/electricCar.svg';
import bike from '../../assets/images/svg/electricBike.svg';
import cycle from '../../assets/images/svg/electricCycle.svg';
import scooter from '../../assets/images/svg/electricScooter.svg';

export const cardProperties = [
  {
    id: 1,
    title: 'Electric Vehicles',
    icon: CarIcon,
    content: `A Detailed insight on what Electric Vehicles are and why they are the
    future of transportation.`,
  },
  {
    id: 2,
    title: 'Charging Stations',
    icon: PowerIcon,
    content: `Worried where to charge your EV? Here, take a quick look at the major
    charging stations near you!`,
  },
  {
    id: 3,
    title: 'EV Guide',
    icon: SettingsIcon,
    content: `Have any doubts regarding EVs? Visit our EV guide to get rid of all
    your doubts in one go!`,
  },
  {
    id: 4,
    title: 'FAQ’s',
    icon: FaqIcon,
    content: `Some of the most common and frequent questions regarding EVs answered
    in the simplest way possible.`,
  },
];

export const electricVehiclesImages = [
  {
    id: 1,
    image: car,
    title: 'Electic Car',
  },
  {
    id: 2,
    image: bike,
    title: 'Electric Bike',
  },
  {
    id: 3,
    image: cycle,
    title: 'Electric Cycle',
  },
  {
    id: 4,
    image: scooter,
    title: 'Electric Scooter',
  },
];

export const Ev_stories = [
  {
    id: 1,
    image:
      'https://media.automotiveworld.com/app/uploads/2020/08/21125254/EV-plug-closeup.jpg',
    title: 'Electric vehicles: The now, the near future and the never again',
    description:
      'The electric vehicle (EV) revolution is speeding up, but it can only go so far without the necessary infrastructure and technology. As thinking shifts from fossil fuels to all-electric, visions of a brighter, more optimistic world come into view. ',
    url: 'https://www.automotiveworld.com/articles/electric-vehicles-the-now-the-near-future-and-the-never-again/',
  },
  {
    id: 2,
    image:
      'https://media.nature.com/w1248/magazine-assets/d41586-021-02222-1/d41586-021-02222-1_19561190.jpg',
    title: 'Electric cars and batteries: how will the world produce enough?',
    description:
      'Batteries for an electric car are assembled at the Audi production plant in Brussels. Credit: Audi AG The age of the electric car is upon us. Earlier this year, the US automobile giant General Motors announced that it aims to stop selling petrol-powered and diesel models by 2035. Audi, based in Germany, plans to stop producing such vehicles by 2033.',
    url: 'https://www.nature.com/articles/d41586-021-02222-1',
  },
  {
    id: 3,
    image:
      'https://www.iisd.org/sites/default/files/styles/banner_desktop/public/2021-07/electric-vehicle-car-charging-station-delhi-india.jpg?h=160995c1&itok=jp1S2bep',
    title: 'Electric Vehicle Benefits and Considerations',
    description:
      'In 2019, the United States imported about 3% of the petroleum it consumed, and the transportation sector accounts for approximately 30% of total U.S. energy needs and 70% of U.S.',
    url: 'https://afdc.energy.gov/fuels/electricity_benefits.html',
  },
  {
    id: 4,
    image:
      'https://media.springernature.com/lw685/springer-static/image/art%3A10.1186%2F2190-4715-24-14/MediaObjects/12302_2011_Article_53_Fig1_HTML.jpg?as=webp',
    title: 'Electric cars: technical characteristics and environmental impacts',
    description:
      'Electric vehicles have been identified as being a key technology in reducing future emissions and energy consumption in the mobility sector. The focus of this article is to review and assess the energy efficiency and the environmental impact of battery electric cars (BEV), ',
    url: 'https://enveurope.springeropen.com/articles/10.1186/2190-4715-24-14',
  },
  {
    id: 5,
    image:
      'https://www.iisd.org/sites/default/files/styles/banner_desktop/public/2021-07/electric-vehicle-car-charging-station-delhi-india.jpg?h=160995c1&itok=jp1S2bep',
    title: 'Is India Ready for an Electric Vehicle Revolution?',
    description:
      "IISD's Tom Moerenhout looks at what India needs to do to keep up with countries already embracing the trend toward EVs. ",
    url: 'https://www.iisd.org/articles/india-electric-vehicle-revolution',
  },
  {
    id: 6,
    image:
      'https://media.automotiveworld.com/app/uploads/2020/08/21125254/EV-plug-closeup.jpg',
    title: 'Electric vehicles: The now, the near future and the never again',
    description:
      'The electric vehicle (EV) revolution is speeding up, but it can only go so far without the necessary infrastructure and technology. As thinking shifts from fossil fuels to all-electric, visions of a brighter, more optimistic world come into view. ',
    url: 'https://www.automotiveworld.com/articles/electric-vehicles-the-now-the-near-future-and-the-never-again/',
  },
  {
    id: 7,
    image:
      'https://media.nature.com/w1248/magazine-assets/d41586-021-02222-1/d41586-021-02222-1_19561190.jpg',
    title: 'Electric cars and batteries: how will the world produce enough?',
    description:
      'Batteries for an electric car are assembled at the Audi production plant in Brussels. Credit: Audi AG The age of the electric car is upon us. Earlier this year, the US automobile giant General Motors announced that it aims to stop selling petrol-powered and diesel models by 2035. Audi, based in Germany, plans to stop producing such vehicles by 2033.',
    url: 'https://www.nature.com/articles/d41586-021-02222-1',
  },
  {
    id: 8,
    image:
      'https://afdc.energy.gov/assets/images/fuels/photo-electricity-benefits-4e5871bc.jpg',
    title: 'Electric Vehicle Benefits and Considerations',
    description:
      'In 2019, the United States imported about 3% of the petroleum it consumed, and the transportation sector accounts for approximately 30% of total U.S. energy needs and 70% of U.S.',
    url: 'https://afdc.energy.gov/fuels/electricity_benefits.html',
  },
  {
    id: 9,
    image:
      'https://media.springernature.com/lw685/springer-static/image/art%3A10.1186%2F2190-4715-24-14/MediaObjects/12302_2011_Article_53_Fig1_HTML.jpg?as=webp',
    title: 'Electric cars: technical characteristics and environmental impacts',
    description:
      'Electric vehicles have been identified as being a key technology in reducing future emissions and energy consumption in the mobility sector. The focus of this article is to review and assess the energy efficiency and the environmental impact of battery electric cars (BEV), ',
    url: 'https://enveurope.springeropen.com/articles/10.1186/2190-4715-24-14',
  },
  {
    id: 10,
    image:
      'https://www.iisd.org/sites/default/files/styles/banner_desktop/public/2021-07/electric-vehicle-car-charging-station-delhi-india.jpg?h=160995c1&itok=jp1S2bep',
    title: 'Is India Ready for an Electric Vehicle Revolution?',
    description:
      "IISD's Tom Moerenhout looks at what India needs to do to keep up with countries already embracing the trend toward EVs. ",
    url: 'https://www.iisd.org/articles/india-electric-vehicle-revolution',
  },
  {
    id: 11,
    image:
      'https://media.automotiveworld.com/app/uploads/2020/08/21125254/EV-plug-closeup.jpg',
    title: 'Electric vehicles: The now, the near future and the never again',
    description:
      'The electric vehicle (EV) revolution is speeding up, but it can only go so far without the necessary infrastructure and technology. As thinking shifts from fossil fuels to all-electric, visions of a brighter, more optimistic world come into view. ',
    url: 'https://www.automotiveworld.com/articles/electric-vehicles-the-now-the-near-future-and-the-never-again/',
  },
  {
    id: 12,
    image:
      'https://media.nature.com/w1248/magazine-assets/d41586-021-02222-1/d41586-021-02222-1_19561190.jpg',
    title: 'Electric cars and batteries: how will the world produce enough?',
    description:
      'Batteries for an electric car are assembled at the Audi production plant in Brussels. Credit: Audi AG The age of the electric car is upon us. Earlier this year, the US automobile giant General Motors announced that it aims to stop selling petrol-powered and diesel models by 2035. Audi, based in Germany, plans to stop producing such vehicles by 2033.',
    url: 'https://www.nature.com/articles/d41586-021-02222-1',
  },
  {
    id: 13,
    image:
      'https://afdc.energy.gov/assets/images/fuels/photo-electricity-benefits-4e5871bc.jpg',
    title: 'Electric Vehicle Benefits and Considerations',
    description:
      'In 2019, the United States imported about 3% of the petroleum it consumed, and the transportation sector accounts for approximately 30% of total U.S. energy needs and 70% of U.S.',
    url: 'https://afdc.energy.gov/fuels/electricity_benefits.html',
  },
  {
    id: 14,
    image:
      'https://media.springernature.com/lw685/springer-static/image/art%3A10.1186%2F2190-4715-24-14/MediaObjects/12302_2011_Article_53_Fig1_HTML.jpg?as=webp',
    title: 'Electric cars: technical characteristics and environmental impacts',
    description:
      'Electric vehicles have been identified as being a key technology in reducing future emissions and energy consumption in the mobility sector. The focus of this article is to review and assess the energy efficiency and the environmental impact of battery electric cars (BEV), ',
    url: 'https://enveurope.springeropen.com/articles/10.1186/2190-4715-24-14',
  },
  {
    id: 15,
    image:
      'https://www.iisd.org/sites/default/files/styles/banner_desktop/public/2021-07/electric-vehicle-car-charging-station-delhi-india.jpg?h=160995c1&itok=jp1S2bep',
    title: 'Is India Ready for an Electric Vehicle Revolution?',
    description:
      "IISD's Tom Moerenhout looks at what India needs to do to keep up with countries already embracing the trend toward EVs. ",
    url: 'https://www.iisd.org/articles/india-electric-vehicle-revolution',
  },
];

export const faqQuestion = [
  {
    id: 1,
    question: 'How fast can an Electric Car/Bike go?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 2,
    question: 'Are electric vehicle efficient?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 3,
    question:
      'How long before we would need to change the battery pack on an EV?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 4,
    question: 'How much does it cost to charge a plug-in vehicle?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 5,
    question: 'How long does it take to charge an EV?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    id: 6,
    question:
      "Why are Lithium-Ion based EV's expensive as compared to oil based vehicles?",
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
];

export const LandingPageProps = {};
