import '../../../styles/Faq.css';
import QuestionBlock from '../../FaqSection/QuestionBlock';

const CatagoryQuestions = ({ name, questions }) => {
  return (
    <div className="">
      <div className="flex justify-center place-items-center gap-4 mb-4">
        <div className="line-cat"></div>
        <label className="text-white text-3xl font-semibold font-Poppins ">
          {name}
        </label>
        <div className="line-cat"></div>
      </div>
      {questions.map(({ question, answer, id }) => {
        return (
          <div className="p-3" key={id}>
            <QuestionBlock answer={answer} question={question} />
          </div>
        );
      })}
    </div>
  );
};

export default CatagoryQuestions;
