import FeedbackSection from '../../components/EvStory/FeedbackSection';
import StoryHeroSection from '../../components/EvStory/StoryHeroSection';
import StorySection from '../../components/EvStory/StorySection';
import TopStories from '../../components/EvStory/TopStories';
import Footer from '../../components/Footer/Footer';

const EvStory = () => {
  return (
    <div>
      <StoryHeroSection />
      <StorySection />
      <FeedbackSection />
      <TopStories />
      <Footer />
    </div>
  );
};

export default EvStory;
