import EvStoriesCard from '../EvStories/EvStoriesCard/EvStoriesCard';

const EvStoriesGrid = ({ articles }) => {
  return (
    <div className="bg-custom-black pb-20">
      <div className="flex justify-center">
        <div className="p-4 pt-10 lg:w-2/3 w-full">
          <p className="text-white font-Poppins text-sm  font-normal text-center">
            take a quick look at various EV stories and also read through some
            mind-opening articles related to Electric vehicles and their various
            aspects like charging, performance, etc.
          </p>
        </div>
      </div>

      <div className="justify-items-center grid 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-3 mt-20 gap-y-10">
        {articles.map(({ id, image, title, description, url }) => {
          return (
            <EvStoriesCard
              desc={description}
              image={image}
              title={title}
              url={url}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EvStoriesGrid;
