import { Helmet } from 'react-helmet';
import { vehiclesOptionsList } from '../constants';
import AddVehicles from '../../../components/Admin/AddVehicles/AddVehicles';

const CreateVehicles = () => {
  return (
    <>
      <Helmet>
        <title>Create Vehicles</title>
        <meta content="Create Vehicles" name="description" />
      </Helmet>
      <div className="lg:col-span-4 col-span-5">
        <AddVehicles optionsList={vehiclesOptionsList} />
      </div>
    </>
  );
};

export default CreateVehicles;
