import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useState } from 'react';
// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module
import 'swiper/modules/scrollbar/scrollbar.scss';
import EvImageCard from '../EvImageCard/EvImageCard';

const EvMobileSlider = ({ images }) => {
  const [onHoverIndex, setOnHoverIndex] = useState(0);

  return (
    <Swiper
      autoplay={{ delay: 10000, disableOnInteraction: true }}
      modules={[Navigation, Pagination, Scrollbar, Autoplay]}
      onSlideChange={() => setOnHoverIndex(0)}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      slidesPerView={1}>
      {images.map(({ id, image, title }) => {
        return (
          <SwiperSlide key={id} onClick={() => setOnHoverIndex(id)}>
            <EvImageCard
              hover={onHoverIndex === id}
              src={image}
              title={title}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default EvMobileSlider;
