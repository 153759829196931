import '../../../styles/admin.css';
import { useState } from 'react';
import CreateFormHeader from '../CreateFormHeader/CreateFormHeader';
import CreateFormTitleInput from '../CreateFormTitleInput/CreateFormTitleInput';
import CreateFormDescriptionInput from '../CreateFormDescriptionInput/CreateFormDescriptionInput';
import CreateFormUploadImagesSection from '../CreateFormUploalImagesSection/CreateFormUploadImagesSection';
import CreateFormAddFeaturesSection from '../CreateFormAddFeaturesSection/CreateFormAddFeaturesSection';
const AddComponent = ({ optionsList }) => {
  const [selectedItem, setSelectedItem] = useState('');

  const handleSelectVehicle = (label) => {
    setSelectedItem(label);
  };
  return (
    <div className="pt-10 pr-10 pl-10">
      <CreateFormHeader />
      <div className="pt-6 scroll-style">
        <form>
          <CreateFormTitleInput
            handleSelectVehicle={handleSelectVehicle}
            isSelect={true}
            optionsList={optionsList}
            placeholderText={'Title Of Component'}
            selectedType={selectedItem}
          />
          <CreateFormDescriptionInput
            placeholderText={'Description of Component'}
          />
          <CreateFormUploadImagesSection />
          <CreateFormAddFeaturesSection />
          <div className="flex justify-center gap-3 mt-16 mb-10">
            <button className="font-Poppins text-sm font-thin focus:outline-none bg-custom-yellow px-10 py-2 rounded">
              Save
            </button>
            <button className="font-Poppins text-sm text-white font-thin border border-white focus:outline-none bg-custom-gray px-10 py-2 rounded ">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddComponent;
