import { Ev_stories as Stories } from '../../containers/Home/constants';
import EvStoriesCardsSlider from '../EvStories/EvStoriesCardsSlider/EvStoriesCardsSlider';

const TopStories = () => {
  return (
    <div className="bg-custom-black">
      <div className="flex justify-start gap-2 text-xl font-bold font-Poppins text-white place-items-center px-5 py-10">
        <p>Read latest EV Stories</p>
        <div className="latest-stories-line"></div>
      </div>
      <EvStoriesCardsSlider stories={Stories} />
    </div>
  );
};

export default TopStories;
