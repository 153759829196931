import { RightArrow } from '../../../assets/Icons/Icons';
import { useNavigate } from 'react-router-dom';

const CreateFormHeader = () => {
  let navigate = useNavigate();
  return (
    <div className="sticky">
      <div className="text-white font-Poppins flex justify-start place-items-center gap-2">
        <div
          className="rotate-arrow cursor-pointer"
          onClick={() => navigate(-1)}>
          <RightArrow fill="white" height="15" width="15" />
        </div>
        <label>Add New</label>
      </div>
      <div className="pt-2">
        <hr></hr>
      </div>
    </div>
  );
};

export default CreateFormHeader;
