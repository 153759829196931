import { PlusSignIcon } from '../../../assets/Icons/Icons';
import InputElement from '../InputElement/InputElement';
import { useState } from 'react';

const CreateFormAddFeaturesSection = () => {
  const [addInputBox, setaddInputBox] = useState(1);
  return (
    <div>
      <div className="px-4 flex justify-between">
        <label className="font-Poppins text-white font-bold ">
          Add Features
        </label>
        <div
          className="p-2 cursor-pointer"
          onClick={() => setaddInputBox(addInputBox + 1)}>
          <PlusSignIcon
            height="20"
            strokeColor="white"
            strokeWidth="2"
            width="20"
          />
        </div>
      </div>
      <div className="pt-2">
        <hr></hr>
      </div>
      <div className="pt-6">
        {[...Array(addInputBox)].map((_, i) => {
          return (
            <div className="pt-2" key={i}>
              <InputElement dataKey={i + 1} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CreateFormAddFeaturesSection;
