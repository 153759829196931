import '../../../styles/ElectricVehicles.css';
import { RightArrow } from '../../../assets/Icons/Icons';
import { useState } from 'react';

const EvImageCard = ({ src, title, onclickfn }) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}>
      <div>
        <div className={`${onHover && 'hover-image'} py-3`}>
          <img alt={title} src={src} />
        </div>
        <div
          className={`${
            onHover && 'hover-info'
          } before-hover-info bg-hover-background explore-button`}>
          <label className="text-white px-3  font-Poppins text-xl text-left font-bold">
            {' '}
            {title}{' '}
          </label>
          <div className="pt-5 flex justify-center ">
            <button className="bg-custom-yellow  cursor-pointer flex  justify-center place-items-center py-3 w-11/12 gap-5 font-Poppins text-custom-black border-2 border-custom-yellow font-bold rounded">
              Explore
              <RightArrow fill="#041018" height="15" width="15" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvImageCard;
