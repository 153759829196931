import { PlusSignIcon } from '../../../assets/Icons/Icons';
import '../../../styles/admin.css';
import { useState } from 'react';

const UploadImage = ({
  className = 'upload-image-button',
  isCoverPhoto = false,
  handleImageArray,
}) => {
  const [displayImage, setDisplayImage] = useState(false);

  const handleImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        setDisplayImage(e.target.result);
        handleImageArray(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <div>
      {!displayImage ? (
        <div>
          <label className={className} htmlFor="file-input">
            <PlusSignIcon
              height="20"
              strokeColor="white"
              strokeWidth="2"
              width="20"
            />
          </label>
          <input
            accept="image/*"
            className="hidden"
            id="file-input"
            onChange={handleImage}
            type="file"></input>
        </div>
      ) : !isCoverPhoto ? (
        <div className={className} onClick={() => setDisplayImage(false)}>
          <img alt="" className={className} src={displayImage}></img>
        </div>
      ) : (
        <div
          className="flex justify-center"
          onClick={() => setDisplayImage(false)}>
          <img alt="" src={displayImage}></img>
        </div>
      )}
    </div>
  );
};

export default UploadImage;
