import { PlusIcon } from '../../assets/images/svg/index';
import EvImageCard from './EvImageCard/EvImageCard';
import { useMediaQuery } from 'react-responsive';
import {
  isLargeScreen,
  isMediumScreen,
} from '../../utlils/constants/constants';
import EvMobileSlider from './EvMobileSlider/EvMobileSlider';

const ElectricVehicles = ({ images }) => {
  const isLarge = useMediaQuery(isLargeScreen);
  const isMedium = useMediaQuery(isMediumScreen);

  return (
    <div className="px-4 mx-auto my-12 mb-40 bg-custom-black mt-28 md:px-12">
      <div>
        <div className="flex justify-center place-items-center lg:space-x-3 mb-11">
          <PlusIcon />
          <h1 className=" text-3xl font-bold text-white text-center font-Poppins ">
            ELECTRIC VEHICLES
          </h1>
          <PlusIcon />
        </div>
        {isLarge || isMedium ? (
          <div className="flex flex-wrap justify-center p-6 lg:space-x-6">
            {images.map(({ id, image, title }) => {
              return (
                <div className="pl-3 py-3" key={id}>
                  <EvImageCard src={image} title={title} />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="p-3">
            <EvMobileSlider images={images} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ElectricVehicles;
