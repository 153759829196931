import { DropDownArrow } from '../../../assets/Icons/Icons';
import '../../../styles/admin.css';

const CustomSelect = ({
  optionsList,
  defaultText,
  showOptionList = false,
  handleSelectVehicle,
  selectedItem,
}) => {
  return (
    <div>
      <div className="font-Poppins bg-search-background rounded  px-6 py-3 flex gap-3 justify-between place-items-center w-48">
        <label className="font-medium">{selectedItem || defaultText}</label>
        <DropDownArrow fill="black" height="20" width="20" />
      </div>
      {showOptionList && (
        <div className="bg-search-background fixed">
          {optionsList.map(({ id, label }) => {
            return (
              <div
                className={`${
                  selectedItem === label && 'bg-custom-yellow font-semibold'
                } py-3 px-9 w-48 labelborder font-medium`}
                key={id}
                onClick={() => handleSelectVehicle(label)}>
                <label className="font-Poppins text-sm">{label}</label>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
