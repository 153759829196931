import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Home from '../containers/Home/Home';
import FAQ from '../containers/FAQ/Faq';
import EvStoriesPage from '../containers/EvStoriesPage/EvStoriesPage';
import EvStoryPage from '../containers/EvStoryPage/EvStoryPage';
import Dashboard from '../containers/Admin/Dashboard/index';
import EvChargingPage from '../containers/EvChargingPage/EvChargingPage';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} exact path="/" />
        <Route element={<FAQ />} exact path="/faq" />
        <Route element={<EvStoriesPage />} exact path="/evstories" />
        <Route element={<EvStoryPage />} path="/evstories/1" />
        <Route element={<Dashboard />} path="/dashboard/*" />
        <Route element={<EvChargingPage />} path="/evcharging" />
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
