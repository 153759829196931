import styled, { keyframes, css } from 'styled-components';

const navbarAnimation = keyframes`
 0% { transform: translateX(85%); }
 100% { transform: translateX(0%); }
 `;

const navmenuAnimation = keyframes`
    from {
      opacity: 0;
    }

    20% {
        opacity: 0;
    }
    
    100% {
      opacity: 1;
    }
`;

function createAnimationForNavmenu(navMenuLength) {
  let styles = '';
  let x = [];

  for (let i = 1; i <= navMenuLength; i++) {
    styles = css`
      &.nav-open
        > div:nth-child(${css`
            ${i}
          `}) {
        animation: ${css`
          ${navmenuAnimation} ${1.1 * i}s;
        `};
      }
      &.nav-open .nav-button {
        animation: ${css`
          ${navmenuAnimation} ${1 * navMenuLength}s;
        `};
      }
    `;
    x.push(styles);
  }
  return x;
}

export let DesktopNavbar = styled.div`
  &.nav-open {
    animation: ${navbarAnimation} 2s;
    animation-timing-function: ease-in;
  }

  &.nav-close {
    display: none;
  }

  ${(p) =>
    css`
      ${createAnimationForNavmenu(p.navMenuLength)}
    `}
`;

export let DesktopNavbarIcon = styled.div`
  &#nav-icon1 {
    width: 34px;
    height: 45px;
    position: relative;
    margin: 0 auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
  }

  &#nav-icon1 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #feffff;
    border-radius: 3px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.35s ease-in-out;
    -moz-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
  }

  &#nav-icon1 span:nth-child(1) {
    top: 2px;
  }

  &#nav-icon1 span:nth-child(2) {
    top: 14px;
  }

  &#nav-icon1 span:nth-child(3) {
    top: 26px;
  }

  &#nav-icon1.open span:nth-child(1) {
    top: 19px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  &#nav-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -180px;
  }

  &#nav-icon1.open span:nth-child(3) {
    top: 19px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
`;
